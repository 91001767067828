import React, { useState } from 'react'
import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin
} from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import { SvgChevronLeft, SvgChevronRight } from 'Components/Icons'
import person from 'Static/images/person.png'

const styles = {
  arrowSize: 'h-16 w-16',
  arrowHomepage: 'select-none stroke-current text-white',
  arrowDisabled: 'select-none stroke-current text-lightGrey',
  arrowPartners: 'select-none stroke-current text-darkGrey',
  row: 'flex flex-row justify-between w-full space-x-8'
}

const items = {
  homepage: [
    {
      name: 'Madeleine Westerhout',
      description:
        'Author & Former Director of Oval Office Operations for POTUS',
      text: 'I turned to HelloPrivacy to help keep me and my loved ones safe. I rely on them to keep personal information about me and my family off of high-risk sites, like my address, relatives, phone and email, and have been very pleased with the results.”'
    },
    {
      name: 'Jeremy Kagan',
      description:
        'Managing Director, Eugene Lang Entrepreneurship Center, Columbia US',
      text: 'HelloPrivacy is really effective. By just entering in my name, age and location, the software found exposed private info relating to me and my family in a matter of seconds. It successfully removed it all for me, saving hours of work.”'
    },
    {
      name: 'Asher Jay',
      description:
        'Advisory Council Member at The Ocean Foundation and Board of Directors at One More Generation',
      text: 'As someone who’s dealt with a stalker in the past, I wanted to remove information like my home address, phone number and other information. HelloPrivacy helped find and remove over 100 exposed piece of personal data, including sensitive info about me or my relatives.”'
    },
    {
      name: 'Savannah Sachs',
      description: 'CEO Tula Skincare',
      text: 'I had no idea that so many sites exposed my contact info until I did a Privacy Scan. I immediately opted into HelloPrivacy’s private info removal service and have been encouraged to see these records get removed. I would recommend HelloPrivacy to anyone!”'
    },
    {
      name: 'Chuck Clarvit',
      description:
        'Former Managing Director, Blackrock  CEO & Board Member AgeneBio',
      text: 'This product is essential. It removes information about you that no sane person would want to have shopped around online. HelloPrivacy is the most economical, all-encompassing solution out there. I recommend this service.”'
    },
    {
      name: 'Linda Dickerson Hartsock',
      description:
        'Executive Director of TechStars & Blackstone Launchpad at S.U.',
      text: 'Syracuse University licensed HelloPrivacy’s tools on behalf of tens of thousands of students and alumni. The partnership has provided immense value, helping us protect the digital privacy of our community.”'
    },
    {
      name: 'Jason Loia',
      description:
        'Privacy Expert and Former President and CEO of various consumer privacy companies',
      text: 'I’ve been President and CEO of multiple consumer privacy businesses, and am thoroughly impressed by HelloPrivacy. It successfully found and removed 299 exposed pieces of personal information on my behalf.”'
    }
  ],
  partners: [
    {
      name: 'Darius Fisher',
      description: 'CEO and Co-Founder Status Labs',
      text: 'We partnered with HelloPrivacy to scan and remove exposed private information for our most valuable clients. We have been impressed with the results, and so have our clients. ​​ We will definitely continue to offer these services moving forward.”'
    },
    {
      name: 'Julie Cadena',
      description: 'Intelligence Team, American Family Insurance',
      text: 'American Family Insurance is all too aware of the risks posed by exposed private info on the web. That’s why we sought a solution to protect our executive team’s personal online data. We’re loving what we’re seeing and in discussions to expand the number of people we are monitoring/removing information for.”'
    },
    {
      name: 'Linda Dickerson Hartsock',
      description:
        'Executive Director of TechStars & Blackstone Launchpad at S.U.',
      text: 'Syracuse University licensed HelloPrivacy’s tools on behalf of tens of thousands of students and alumni. The partnership has provided immense value, helping us protect the digital privacy of our community.”'
    },
    {
      name: 'Jason Loia',
      description:
        'Privacy Expert and Former President and CEO of various consumer privacy companies',
      text: 'I’ve been President and CEO of multiple consumer privacy businesses, and am thoroughly impressed by HelloPrivacy. Having tested the product extensively myself, I have been thoroughly impressed with the consistency of results and remain a very happy user.”'
    }
  ]
}

export default function Testimonials(props) {
  const { page } = props

  const [selectedIndex, setSelectedIndex] = useState(0)

  const pageItems = Array.from({ length: 20 }, () => items[page]).flat()

  const arrowStyle =
    page === 'partners'
      ? `${styles.arrowSize} ${styles.arrowPartners}`
      : `${styles.arrowSize} ${styles.arrowHomepage}`

  const configForBreakpoint = (numberOfSlides) => [
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides
      }
    },
    {
      resolve: arrowsPlugin,
      options: {
        arrowLeft: (
          <a className={styles.arrowSize}>
            <SvgChevronLeft className={arrowStyle} />
          </a>
        ),
        arrowLeftDisabled: (
          <a className={styles.arrowSize}>
            <SvgChevronLeft
              className={`${styles.arrowSize} ${styles.arrowDisabled}`}
            />
          </a>
        ),
        arrowRight: (
          <a className={styles.arrowSize}>
            <SvgChevronRight className={arrowStyle} />
          </a>
        ),
        arrowRightDisabled: (
          <a className={styles.arrowSize}>
            <SvgChevronRight
              className={`${styles.arrowSize} ${styles.arrowDisabled}`}
            />
          </a>
        ),
        addArrowClickHandler: true
      }
    }
  ]

  return (
    <Carousel
      value={selectedIndex}
      onChange={(index) => setSelectedIndex(index)}
      offset={48}
      plugins={configForBreakpoint(3)}
      breakpoints={{
        1200: { plugins: configForBreakpoint(2) },
        768: { plugins: configForBreakpoint(1) }
      }}
    >
      {pageItems.map((testimonial, index) => (
        <div key={`testimonial-${index}`}>
          <div className="relative font-body select-none bg-white border border-midGrey h-testimonialHeight w-testimonialWidth px-8 pt-16 pb-4 shadow-lg flex flex-col justify-between text-testimonials">
            <div className="absolute top-0 left-0 right-0 h-2 bg-button" />
            <div>
              <div className="text-leadQuote leading-leadQuote font-bold">
                “
              </div>
              <div style={{ textIndent: '1rem' }}>{testimonial.text}</div>
            </div>
            <div className="flex flex-col" style={{ height: '160px' }}>
              <div className="flex flex-row justify-start items-center mt-8">
                <img className="w-32px h-32px mr-4" src={person} />
                <div className="text-lg font-bold">{testimonial.name}</div>
              </div>
              <div className="leading-loose">{testimonial.description}</div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  )
}
