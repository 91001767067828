import React from 'react'

import { InfoPageHeader, Footer, ArrayBanner } from 'Components'
export default function PrivacyPolicy() {
  return (
    <div className="text-darkerGrey text-lg">
      <ArrayBanner />
      <InfoPageHeader
        pageTitle="Privacy Policy"
        subTitle="Effective Date of Current Policy: April 3rd, 2019"
      />

      <div
        id="privacy-policy"
        className="legal-text text-midGrey text-lg mx-24 lg:mx-auto max-w-text"
      >
        <h2>INTRODUCTION</h2>

        <p>
          Protecting Consumer Privacy is a core tenet of our mission at
          HelloPrivacy LLC, DBA BrandYourself (henceforth called{' '}
          <strong>"HelloPrivacy"</strong>). We believe consumers should be able
          to enjoy the web without worrying about their privacy, safety or
          security.
        </p>
        <p>
          For HelloPrivacy, that means not only building tools to help consumers
          protect their privacy against other companies, but also considering
          your privacy in every decision we make internally. We consider privacy
          in every decision we make from structuring our own business model to
          designing our technology. We also strive to offer transparency in
          everything we do.
        </p>
        <p>
          While privacy policies are inevitably dense and filled with legalese,
          we wanted to pull out a few important elements that form the backbone
          of how our policy operates.
        </p>

        <ol className="list-decimal">
          <li>
            <p>
              <strong>You are not the product:</strong> HelloPrivacy never sells
              or monetizes your data to a third party. Period. Our business
              model has alway been simple. We charge a subscription fee for our
              premium products/services. We made a decision early on to never
              use the data of our users to build or grow another revenue stream,
              especially one our users can’t see. Since we do not monetize our
              data, there is no incentive to build rich data profiles on our
              users or abuse your data in anyway. Our goal is to protect your
              data, not give it away.
            </p>
          </li>
          <li>
            <p>
              <strong>We protect your data at all costs:</strong> HelloPrivacy
              takes every precaution to limit the data we do have on users and
              to keep that data as secure as possible. While in an ideal world
              we could limit that data to zero, we need some data for our
              product to actually function and provide you with value. For
              example, we need to scan your social media posts for our “clean up
              social media” feature to work. That said, we only use/store data
              deemed absolutely necessary for a function to work for only as
              long as we need it to fulfill those functions. Here are some of
              the measures we take to safeguard your data:
            </p>
          </li>
          <ol className="list-lowerAlpha">
            <li>
              <p>
                <strong>Technical measures:</strong> Any information we store is
                transmitted in AES 256-bit end-to-end encryption format using
                SSL technology, and is deleted once it is no longer needed. We
                use up-to-date software and operating systems and firewall
                protection.
              </p>
            </li>
            <li>
              <p>
                <strong>Administrative measures:</strong> Access to your PII is
                limited to authorized personnel who have a legitimate need to
                access it based on their job descriptions. These personnel are
                properly vetted, background checked and trained and given only
                as much access control as necessary to do their jobs.
              </p>
            </li>
            <li>
              <p>
                <strong>Physical Measures:</strong> All servers are kept in a
                secure location. Access to PII is not permitted via the Internet
                except using an encrypted virtual private network.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies:</strong> We strive to use only the absolute
                minimum amount of cookies required to make your experience
                seamless. For example, while many sites store lots of
                unnecessary cookies that track behavior, we only store ones that
                are critical to your experience, such as ones that allow you to
                return to HelloPrivacy without having to log-in every single
                time. And if you ever want to clear your cookies (from or
                HelloPrivacy or from any other company),{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://us.norton.com/internetsecurity-privacy-how-to-clear-cookies.html"
                >
                  here’s how to do it
                </a>
                .
              </p>
            </li>
          </ol>
          <li>
            <p>
              <strong>YOU are in control:</strong> You always have the right to{' '}
              <a href="mailto:privacysupport@helloprivacy.com">
                request what information
              </a>{' '}
              we have about you,{' '}
              <a href="/account-settings">opt out of any marketing messaging</a>{' '}
              in your account settings, or{' '}
              <a href="mailto:privacysupport@helloprivacy.com">
                delete your account
              </a>
              . You can also contact us by{' '}
              <a href="mailto:privacysupport@helloprivacy.com">emailing us</a>,
              calling us at (646) 863-8226, or live-chatting us (just click the
              “Live Chat” button on the bottom of your screen). While laws like
              CCPA and GDPR offer these types of rights to residents of certain
              areas, we offer them to all of our users. To learn more about the
              information we collect, how we use it, etc you can read the rest
              of our policy.
            </p>
          </li>
          <ol className="list-lowerAlpha">
            <li>
              <p>
                Here are links that will allow you to opt out tracking done by
                our various partners on this site. Their cookies and related
                tracking elements are NOT part of our private VPN servers and
                will never ever be placed there for tracking by us.
              </p>
            </li>
            <ol className="list-lowerRoman">
              <li>
                <p>Google (Advertising and Analytics)</p>
              </li>
              <ol className="list-decimal">
                <li>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/settings/u/0/ads/authenticated"
                    >
                      https://www.google.com/settings/u/0/ads/authenticated
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://tools.google.com/dlpage/gaoptout"
                    >
                      https://tools.google.com/dlpage/gaoptout
                    </a>
                  </p>
                </li>
              </ol>
              <li>
                <p>Facebook (Advertising and Analytics)</p>
              </li>
              <ol className="list-decimal">
                <li>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/policies/cookies/"
                    >
                      https://www.facebook.com/policies/cookies/
                    </a>
                  </p>
                </li>
              </ol>
              <li>
                <p>Bing</p>
              </li>
              <ol className="list-decimal">
                <li>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://account.microsoft.com/privacy/ad-settings"
                    >
                      https://account.microsoft.com/privacy/ad-settings
                    </a>
                  </p>
                </li>
              </ol>
              <li>
                <p>LinkedIn</p>
              </li>
              <ol className="list-decimal">
                <li>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                    >
                      https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                    </a>
                  </p>
                </li>
              </ol>
            </ol>
          </ol>
        </ol>

        <div>
          <h2>1. PURPOSE</h2>
          <p>
            This Privacy Policy is incorporated by reference into the
            HelloPrivacy Terms of Service (the <strong>“Terms”</strong>). The
            terms “HelloPrivacy,” “we,” and “us” include HelloPrivacy LLC and
            our affiliates and subsidiaries. This Privacy Policy explains our
            online and offline information practices, the kinds of information
            we may collect, how we intend to use and share that information, and
            how you can opt-out of a use or correct or change such information.
            All other terms not defined in Section 13 or otherwise herein will
            have the meanings set forth in the Terms.
          </p>

          <h2>2. SCOPE</h2>
          <p>
            This Privacy Policy applies to Personal Information that is
            Processed by HelloPrivacy in the course of our business, including
            on HelloPrivacy websites (each a “Site”), mobile applications,
            forums, blogs, and other online or offline offerings (together with
            any and all future online and offline offerings operated by or on
            behalf of HelloPrivacy, the “Services”). All individuals whose
            responsibilities include the Processing of Personal Information on
            behalf of HelloPrivacy are expected to protect that data by
            adherence to this Privacy Policy. This Privacy Policy is intended to
            meet requirements globally, including those in North America,
            Europe, APAC, and other jurisdictions.
          </p>

          <h2>
            3. TRANSPARENCY/NOTICE—TYPES OF PERSONAL INFORMATION WE COLLECT AND
            HOW WE USE IT
          </h2>
          <p>
            The types of Personal Information we may collect (directly from you
            or from Third-Party sources) and our privacy practices depend on the
            nature of the relationship you have with HelloPrivacy and the
            requirements of applicable law. Some of the ways that HelloPrivacy
            may collect Personal Information include:
          </p>
          <ul className="list-disc">
            <li>
              <p>
                You may provide Personal Information directly to HelloPrivacy
                through interacting with the Services, participating in surveys,
                during events such as sweepstakes, and requesting Services, or
                information.
              </p>
            </li>
            <li>
              <p>
                As you navigate the Services, certain passive information may
                also be collected about your visit, including through cookies
                and similar technologies as described below.
              </p>
            </li>
          </ul>
          <p>
            We endeavor to collect only that information which is relevant for
            the purposes of Processing. Below are the ways we collect Personal
            Information and how we use it.
          </p>

          <h2>3.1 TYPES OF PERSONAL INFORMATION WE COLLECT</h2>
          <p>
            HelloPrivacy collects Personal Information regarding its current,
            prospective, and former clients, customers, users, visitors, and
            guests (collectively <strong>“Individuals”</strong>).
          </p>
          <ul className="list-disc">
            <li>
              <p>
                <strong>Information You Provide Directly to Us.</strong> When
                you use the Services or engage in certain activities, such as
                registering for an account with HelloPrivacy, responding to
                surveys, requesting Services or information, or contacting us
                directly, we may ask you to provide some or all of the following
                types of information:
              </p>
            </li>
            <ul className="list-circle">
              <li>
                <p>
                  <strong>Communications with Us.</strong> We may collect
                  Personal Information from you such as name, email address,
                  phone number or mailing address when you contact us or request
                  information about our Services, register for a newsletter or
                  loyalty program, request to receive customer or technical
                  support, and a password when you create an account. We may
                  further collect Personal Information from you such as payment
                  and billing information when you register for a premium
                  Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Surveys.</strong> From time to time, we may contact
                  you to participate in online surveys. If you do decide to
                  participate, you may be asked to provide certain information
                  which may include Personal Information. All information
                  collected from your participation in our surveys is provided
                  by you voluntarily. We may use such information to improve our
                  products, Sites and/or services and in any manner consistent
                  with the policies provided herein.
                </p>
              </li>
              <li>
                <p>
                  <strong>Posting on the Site.</strong> HelloPrivacy may offer
                  publicly accessible pages, blogs, private messages, or
                  community forums. You should be aware that, when you disclose
                  information about yourself in or on HelloPrivacy’s pages,
                  blogs, private messages, and community forums, the Site will
                  collect the information you provide in such submissions,
                  including any Personal Information. If you choose to submit
                  content to any public area of the Site, such content will be
                  considered “public” and will not be subject to the privacy
                  protections set forth herein.
                </p>
              </li>
              <li>
                <p>
                  <strong>Registration for Sweepstakes or Contests.</strong>{' '}
                  Occasionally, HelloPrivacy may run sweepstakes and contests.
                  We ask those who enter in the sweepstakes or contests to
                  provide contact information (e.g., an e-mail address). If you
                  participate in a sweepstakes or contest, your contact
                  information may be used to reach you about the sweepstakes or
                  contest, and for other promotional, marketing and business
                  purposes. All sweepstakes/contests entry forms will provide a
                  way for participants to opt-out of any communications that are
                  not related to awarding prizes.
                </p>
              </li>
              <li>
                <p>
                  <strong>Automatic Data Collection.</strong> We may collect
                  certain information automatically through our Services or
                  other methods of web analysis, such as your Internet protocol
                  (IP) address, cookie identifiers, ISP or mobile carrier,
                  mobile advertising identifiers, MAC address, IMEI, Advertiser
                  ID, and other device identifiers and features that are
                  automatically assigned to your computer or device when you
                  access the Internet, browser type and add-ons and language,
                  proxy server, URL, geo-location information, hardware type,
                  operating system, Internet service provider, pages that you
                  visit before and after using the Services, the date and time
                  of your visit, the amount of time you spend on each page,
                  information about the links you click and pages you view
                  within the Services, and other actions taken through use of
                  the Services such as preferences.
                </p>
              </li>
            </ul>
            <li>
              <p>
                <strong>Information from Other Sources.</strong> We may receive
                information about you from other sources, including through an
                entity that purchases Services that you have access to use, such
                as your employer, school, or university, or Third-Party services
                and organizations to supplement information provided by you. For
                example, if you access our Services through a Third-Party
                application, such as an App Store or a Social Networking Site
                (“SNS”) (e.g., Facebook), we may collect information about you
                from that Third-Party application that you have made public via
                your privacy settings or have otherwise allowed us access to.
                Information we collect through App Stores or SNS accounts may
                include your name, your SNS user identification number, your SNS
                user name, location, sex, birth date, email, profile picture,
                your posts or comments, images you have posted and your contacts
                on the SNS. This supplemental information allows us to verify
                information that you have provided to HelloPrivacy and to
                enhance our ability to provide you with information about our
                business, products, and Services.
              </p>
            </li>
          </ul>

          <h2>3.2 HOW HELLOPRIVACY USES YOUR INFORMATION</h2>
          <p>
            We acquire, hold, use, and Process Personal Information about
            Individuals for a variety of business purposes, including:
          </p>
          <ul className="list-disc">
            <li>
              <p>
                <strong>
                  To Provide Products, Services, or Information Requested.
                </strong>{' '}
                HelloPrivacy may use information about you to fulfill requests
                for products, Services, or information, including information
                about potential or future Services, including to:
              </p>
            </li>
            <ul className="list-circle">
              <li>
                <p>Generally manage Individual information and accounts;</p>
              </li>
              <li>
                <p>Respond to questions, comments, and other requests;</p>
              </li>
              <li>
                <p>
                  Provide access to certain areas, functionalities, and features
                  of HelloPrivacy’s Services;
                </p>
              </li>
              <li>
                <p>
                  Contact you to answer requests for customer support or
                  technical support;
                </p>
              </li>
              <li>
                <p>Allow you to register for events; or</p>
              </li>
              <li>
                <p>
                  Investigate, respond to and resolve complaints and Service
                  issues (e.g., bugs).
                </p>
              </li>
            </ul>
            <li>
              <p>
                <strong>Administrative Purposes.</strong> HelloPrivacy may use
                Personal Information about you for its administrative purposes,
                including to:
              </p>
            </li>
            <ul className="list-circle">
              <li>
                <p>Measure interest in HelloPrivacy’s Services;</p>
              </li>
              <li>
                <p>Develop new products and Services;</p>
              </li>
              <li>
                <p>Ensure internal quality control;</p>
              </li>
              <li>
                <p>Verify Individual identity;</p>
              </li>
              <li>
                <p>
                  Communicate about Individual accounts and activities on
                  HelloPrivacy’s Services and systems, and, in HelloPrivacy’s
                  discretion, changes to any HelloPrivacy policy;
                </p>
              </li>
              <li>
                <p>
                  Send email to the email address you provide to us to verify
                  your account and for informational and operational purposes,
                  such as account management, customer service, or system
                  maintenance;
                </p>
              </li>
              <li>
                <p>Process payment for products or services purchased;</p>
              </li>
              <li>
                <p>Process applications and transactions;</p>
              </li>
              <li>
                <p>Prevent potentially prohibited or illegal activities; or</p>
              </li>
              <li>
                <p>Enforce our Terms.</p>
              </li>
            </ul>
            <li>
              <p>
                <strong>Services via Mobile Devices.</strong> From time to time,
                HelloPrivacy may provide Services that are specifically designed
                to be compatible and used on mobile devices. HelloPrivacy will
                collect certain information that your mobile device sends when
                you use such Services, like a device identifier, user settings,
                location information, mobile carrier, and the operating system
                of your device. Mobile versions of HelloPrivacy’s Services may
                require that users log in with an account. In such cases,
                information about use of mobile versions of the Services may be
                associated with accounts. In addition, HelloPrivacy may enable
                Individuals to download an application, widget, or other tool
                that can be used on mobile or other computing devices. Some of
                these tools may store information on mobile or other devices.
                These tools may transmit Personal Information to HelloPrivacy to
                enable Individuals to access accounts and to enable HelloPrivacy
                to track use of these tools. Some of these tools may enable
                users to email reports and other information from the tool.
                HelloPrivacy may use personal or non-identifiable information
                transmitted to HelloPrivacy to enhance these tools, to develop
                new tools, for quality improvement and as otherwise described in
                this Privacy Policy or in other notices HelloPrivacy provides.
              </p>
            </li>
            <li>
              <p>
                <strong>Sharing Content with Friends or Colleagues.</strong>{' '}
                HelloPrivacy’s Services may offer various tools and
                functionalities. Our referral services may allow you to forward
                or share certain content with a friend or colleague, such as an
                email inviting your friend to use our Services. Email addresses
                that you may provide for a friend or colleague will be used to
                send your friend or colleague the content or link you request,
                but will not be collected or otherwise used by HelloPrivacy or
                any other Third Parties for any other purpose.
              </p>
            </li>
            <li>
              <p>
                <strong>Other Uses.</strong> HelloPrivacy may use Personal
                Information for which we have a legitimate interest, such as
                anti-fraud protection, security and investigations, or any other
                purpose disclosed to you at the time you provide Personal
                Information or with your consent.
              </p>
            </li>
          </ul>

          <h2>
            3.3 COOKIES, PIXEL TAGS/WEB BEACONS, ANALYTICS INFORMATION, AND
            INTEREST-BASED ADVERTISING
          </h2>
          <p>
            We, as well as Third Parties that provide content, advertising, or
            other functionality on our Services, may use cookies, pixel tags,
            local storage, and other technologies (
            <strong>“Technologies”</strong>) to automatically collect
            information through the Services. We use Technologies that are
            essentially small data files placed on your computer, tablet, mobile
            phone, or other devices (referred to collectively as a "device")
            that allow us to record certain pieces of information whenever you
            visit or interact with our sites, services, applications, messaging,
            and tools, and to recognize you across devices. Our use of cookies
            and other similar technologies is described more fully in our Cookie
            Policy.
          </p>
          <ul className="list-disc">
            <li>
              <p>
                <strong>Pixel Tags/Web Beacons.</strong> A pixel tag (also known
                as a web beacon) is a piece of code embedded on the Site that
                collects information about users’ engagement on that web page.
                The use of a pixel allows us to record, for example, that a user
                has visited a particular web page or clicked on a particular
                advertisement.
              </p>
            </li>
            <li>
              <p>
                <strong>Social Media Widgets.</strong> Our Website includes
                social media features such as the Facebook “Like” button and
                LinkedIn (that might include widgets such as the share this
                button or other interactive mini-programs). These features may
                collect your IP address, which page you are visiting on our
                site, and may set a cookie to enable the feature to function
                properly. These social media features are either hosted by a
                Third Party or hosted directly on our Website. Your interactions
                with these features are governed by the privacy policy of the
                company providing it.
              </p>
            </li>
            <li>
              <p>
                <strong>Analytics.</strong> We may also use Google Analytics and
                Google Analytics Demographics and Interest Reporting or other
                analytics platforms to collect information regarding visitor
                behavior and visitor demographics on some of our Services, and
                to develop website content. This analytics data is not tied to
                any Personal Information. For more information about Google
                Analytics, please visit
                www.google.com/policies/privacy/partners/. You can opt out of
                Google’s collection and Processing of data generated by your use
                of the Services by going to
                http://tools.google.com/dlpage/gaoptout.
              </p>
            </li>
          </ul>
          <p>
            If you would like to opt-out of the Technologies we employ on our
            sites, services, applications, or tools, you may do so by blocking,
            deleting, or disabling them as your browser or device permits.
          </p>

          <h2>
            3.4 THIRD-PARTY WEBSITES, SOCIAL MEDIA PLATFORMS, AND SOFTWARE
            DEVELOPMENT KITS
          </h2>
          <p>
            The Site may contain links to other websites and other websites may
            reference or link to our Site or other Services. These other domains
            and websites are not controlled by us, and HelloPrivacy does not
            endorse or make any representations about Third-Party websites or
            social media platforms. We encourage our users to read the privacy
            policies of each and every website and application with which they
            interact. We do not endorse, screen or approve, and are not
            responsible for the privacy practices or content of such other
            websites or applications. Visiting these other websites or
            applications is at your own risk.
          </p>
          <p>
            HelloPrivacy’s Services may include publicly accessible blogs,
            community forums, or private messaging features. The Site and our
            other Services may also contain links and interactive features with
            various social media platforms (e.g., widgets). If you already use
            these platforms, their cookies may be set on your device when using
            our Site or other Services. You should be aware that Personal
            Information which you voluntarily include and transmit online in a
            publicly accessible blog, chat room, social media platform or
            otherwise online, or that you share in an open forum may be viewed
            and used by others without any restrictions. We are unable to
            control such uses of your information when interacting with a social
            media platform, and by using such services you assume the risk that
            the Personal Information provided by you may be viewed and used by
            third parties for any number of purposes.
          </p>
          <p>
            We may use Third-Party software development kits (
            <strong>“SDKs”</strong>) and/or application programming interfaces (
            <strong>“APIs”</strong>) as part of the functionality of our
            Services.
          </p>
          <p>
            HelloPrivacy's use and transfer to any other app of information
            received from Google APIs will adhere to Google API Services User
            Data Policy, including the Limited Use requirements.
          </p>

          <h2>3.5 THIRD-PARTY PAYMENT PROCESSING</h2>
          <p>
            We may sell virtual items or merchandise through our Services. When
            you make purchases through the Services, we process your payments
            through a Third-Party application, including the Apple App Store,
            Google Play App Store, Amazon App Store (together with any similar
            applications, <strong>“App Stores”</strong>) and Social Networking
            Sites such as Facebook. The Third-Party application may collect
            certain financial information from you to process a payment on
            behalf of HelloPrivacy, including your name, email address, address
            and other billing information.
          </p>

          <h2>4. ONWARD TRANSFER—HELLOPRIVACY MAY DISCLOSE YOUR INFORMATION</h2>

          <h2>4.1 INFORMATION WE SHARE</h2>
          <p>
            We may share your information as described in this Privacy Policy
            (e.g., with our Third-Party service providers; to comply with legal
            obligations; to protect and defend our rights and property) or with
            your permission.
          </p>
          <ul className="list-disc">
            <li>
              <p>
                <strong>We Use Vendors and Service Providers.</strong> We may
                share any information we receive with vendors and service
                providers. The types of service providers (processors) to whom
                we entrust Personal Information include (but are not limited to)
                service providers for (i) provision of IT and related services,
                including for maintenance; (ii) provision of information and
                services you request; (iii) payment processing; (iv) customer
                service activities; (v) in connection with the provision of the
                Site; and (vi) analysis, audit, and fraud detection.
                HelloPrivacy has executed appropriate contracts with the service
                providers that prohibit them from using or sharing Personal
                Information except as necessary to perform the contracted
                services on our behalf or to comply with applicable legal
                requirements.
              </p>
            </li>
            <li>
              <p>
                <strong>Displaying to Other Users.</strong> The content you post
                to the Site may be displayed on the Site. Other users of the
                Site may be able to see some information about you, such as your
                name if you submit a review. We are not responsible for privacy
                practices of the other users who will view and use the posted
                information.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Marketing – Interest-Based Advertising and Third Party
                  Marketing.
                </strong>{' '}
                Through our Services, HelloPrivacy may allow Third-Party
                advertising partners to set tracking tools (e.g., cookies) to
                collect information regarding your activities (e.g., your IP
                address, page(s) visited, time of day). These advertising
                partners may use this information (and similar information
                collected from other websites) for purposes of delivering
                targeted advertisements to you when you visit non-HelloPrivacy
                related websites within their networks. This practice is
                commonly referred to as “interest-based advertising” or “online
                behavioral advertising. We may allow access to other data
                collected by the Site to facilitate transmittal of information
                that may be useful, relevant, valuable or otherwise of interest
                to you. If you prefer that we do not share your Personal
                Information with Third-Party advertising partners, you may
                opt-out of such sharing at no cost by following the instructions
                in Section 5 below. Please note that opting out will not remove
                advertising from the pages you visit; it will mean that the ads
                you see may not be matched to your interests.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Disclosures to Protect Us or Others (e.g., as Required by Law
                  and Similar Disclosures).
                </strong>{' '}
                We may access, preserve, and disclose your Personal Information,
                other account information, and content if we believe doing so is
                required or appropriate to: (i) comply with law enforcement or
                national security requests and legal process, such as a court
                order or subpoena; (ii) respond to your requests; (iii) protect
                yours’, ours’ or others’ rights, property, or safety; (iv) to
                enforce HelloPrivacy policies or contracts; (v) to collect
                amounts owed to HelloPrivacy; (vi) when we believe disclosure is
                necessary or appropriate to prevent physical harm or financial
                loss or in connection with an investigation or prosecution of
                suspected or actual illegal activity; or (vii) if we, in good
                faith, believe that disclosure is otherwise necessary or
                advisable.
                <br />
                <br />
                In addition, from time to time, server logs may be reviewed for
                security purposes – e.g., to detect unauthorized activity on the
                Services. In such cases, server log data containing IP addresses
                may be shared with law enforcement bodies in order that they may
                identify users in connection with their investigation of the
                unauthorized activities.
              </p>
            </li>
            <li>
              <p>
                <strong>Merger, Sale, or Other Asset Transfers.</strong> If we
                are involved in a merger, acquisition, financing due diligence,
                reorganization, bankruptcy, receivership, sale of company
                assets, or transition of service to another provider, then your
                information may be sold or transferred as part of such a
                transaction as permitted by law and/or contract. In such event,
                HelloPrivacy will endeavor to direct the transferee to use
                Personal Information in a manner that is consistent with the
                Privacy Policy in effect at the time such Personal Information
                was collected.
              </p>
            </li>
          </ul>

          <h2>4.2 INTERNATIONAL DATA TRANSFERS</h2>
          <p>
            You agree that all Personal Information collected via or by
            HelloPrivacy may be transferred, Processed, and stored anywhere in
            the world, including but not limited to, the United States, the
            European Union, in the cloud, on our servers, on the servers of our
            affiliates or the servers of our service providers. Your Personal
            Information may be accessible to law enforcement or other
            authorities pursuant to a lawful request. By providing information
            to HelloPrivacy, you explicitly consent to the storage of your
            Personal Information in these locations.
          </p>

          <h2>5. OPT-OUT (RIGHT TO OBJECT TO PROCESSING)</h2>
          <h2>5.1 GENERAL</h2>
          <p>
            You have the right to object to and opt out of certain uses and
            disclosures of your Personal Information. Where you have consented
            to HelloPrivacy’s Processing of your Personal Information, you may
            withdraw that consent at any time and opt-out to further Processing
            by contacting HelloPrivacy LLC, 53 West James Street, Suite 401,
            Lancaster, PA 17603, Attn: Privacy Policy. Even if you opt-out, we
            may still collect and use non-Personal Information regarding your
            activities on our Sites and/or information from the advertisements
            on Third-Party websites for non-interest based advertising purposes,
            such as to determine the effectiveness of the advertisements.
          </p>

          <h2>5.2 EMAIL AND TELEPHONE COMMUNICATIONS</h2>
          <p>
            If you receive an unwanted email from us, you can use the
            unsubscribe link found at the bottom of the email to opt-out of
            receiving future emails. We will process your request within a
            reasonable time after receipt. Note that you will continue to
            receive transaction-related emails regarding products or services
            you have requested. We may also send you certain non-promotional
            communications regarding HelloPrivacy and our Services and you will
            not be able to opt out of those communications (e.g., communications
            regarding updates to our Terms or this Privacy Policy).
          </p>
          <p>
            We maintain telephone “do-not-call” and “do-not-mail” lists as
            mandated by law. We process requests to be placed on do-not-mail,
            do-not-phone and do-not-contact lists within 60 days after receipt,
            or such shorter time as may be required by law.
          </p>

          <h2>5.3 MOBILE DEVICES</h2>
          <p>
            HelloPrivacy may occasionally send you push notifications through
            our mobile applications with updates and other notices that may be
            of interest to you. You may at any time opt-out from receiving these
            types of communications by changing the settings on your mobile
            device. HelloPrivacy may also collect location-based information if
            you use our mobile applications. You may opt-out of this collection
            by changing the settings on your mobile device.
          </p>

          <h2>5.4 “DO NOT TRACK”</h2>
          <p>
            Do Not Track (<strong>“DNT”</strong>) is a privacy preference that
            users can set in certain web browsers. DNT is a way for users to
            inform websites and services that they do not want certain
            information about their webpage visits collected over time and
            across websites or online services. Please note that we do not
            respond to or honor DNT signals or similar mechanisms transmitted by
            web browsers.
          </p>

          <h2>5.5 COOKIES AND INTEREST-BASED ADVERTISING</h2>
          <p>
            As noted above and in our Cookie Policy, you may stop or restrict
            the placement of cookies on your computer or remove them from your
            browser by adjusting your web browser preferences. Please note that
            cookie-based opt-outs are not effective on mobile applications.
            However, on many mobile devices, application users may opt out of
            certain mobile ads via their device settings.
          </p>
          <p>
            To be clear, whether you are using our opt-out or an online industry
            opt-out, these cookie-based opt-outs must be performed on each
            device and browser that you wish to have opted-out. For example, if
            you have opted-out on your computer browser, that opt-out will not
            be effective on your mobile device. You must separately opt-out on
            each device. Advertisements on Third-Party websites that contain the
            AdChoices link and that link to this Privacy Policy may have been
            directed to you based on anonymous, non-Personal Information
            collected by advertising partners over time and across websites.
            These advertisements provide a mechanism to opt-out of the
            advertising partners’ use of this information for interest-based
            advertising purposes.
          </p>

          <h2>6. RIGHTS OF ACCESS, RECTIFICATION, ERASURE, AND RESTRICTION</h2>
          <p>
            You may inquire as to whether HelloPrivacy is Processing Personal
            Information about you, request access to Personal Information, and
            ask that we correct, amend or delete your Personal Information where
            it is inaccurate. Where otherwise permitted by applicable law, you
            may contact HelloPrivacy LLC, 53 West James Street, Suite 401,
            Lancaster, PA 17603, Attn: Privacy Policy or use any of the methods
            set out in this Privacy Policy to request access to, receive (port),
            seek rectification, or request erasure of Personal Information held
            about you by HelloPrivacy. Please include your full name, email
            address associated with your account, and a detailed description of
            your data request. Such requests will be processed in line with
            local laws.
          </p>
          <p>
            Although HelloPrivacy makes good faith efforts to provide
            Individuals with access to their Personal Information, there may be
            circumstances in which HelloPrivacy is unable to provide access,
            including but not limited to: where the information contains legal
            privilege, would compromise others’ privacy or other legitimate
            rights, where the burden or expense of providing access would be
            disproportionate to the risks to the Individual’s privacy in the
            case in question or where it is commercially proprietary. If
            HelloPrivacy determines that access should be restricted in any
            particular instance, we will provide you with an explanation of why
            that determination has been made and a contact point for any further
            inquiries. To protect your privacy, HelloPrivacy will take
            commercially reasonable steps to verify your identity before
            granting access to or making any changes to your Personal
            Information.
          </p>

          <h2>7. DATA RETENTION</h2>
          <p>
            HelloPrivacy retains the Personal Information we receive as
            described in this Privacy Policy for as long as you use our Services
            or as necessary to fulfill the purpose(s) for which it was
            collected, provide our Services, resolve disputes, establish legal
            defenses, conduct audits, pursue legitimate business purposes,
            enforce our agreements, and comply with applicable laws. We will
            retain de-personalized information after your account has been
            closed. Your profile may continue to be displayed in the services of
            others (e.g., search engine results) until they refresh their cache.
          </p>

          <h2>8. SECURITY OF YOUR INFORMATION</h2>
          <p>
            We take steps to ensure that your information is treated securely
            and in accordance with this Privacy Policy. Unfortunately, the
            Internet cannot be guaranteed to be 100% secure, and we cannot
            ensure or warrant the security of any information you provide to us.
            We do not accept liability for unintentional disclosure.
          </p>
          <p>
            By using the Site or providing Personal Information to us, you agree
            that we may communicate with you electronically regarding security,
            privacy, and administrative issues relating to your use of the Site.
            If we learn of a security system’s breach, we may attempt to notify
            you electronically by posting a notice on the Site or sending an
            e-mail to you. You may have a legal right to receive this notice in
            writing.
          </p>

          <h2>9. INTERNATIONAL USERS</h2>
          <p>
            By using the Site, HelloPrivacy will transfer data to the United
            States. By choosing to visit the Site, utilize the Services or
            otherwise provide information to us, you agree that any dispute over
            privacy or the terms contained in this Privacy Policy will be
            governed by the laws of the state of Delaware in the United States
            of America, and the adjudication of any disputes arising in
            connection with HelloPrivacy or the Site will be in accordance with
            the Terms.
          </p>
          <p>
            If you are visiting from the European Union or other regions with
            laws governing data collection and use, please note that you are
            agreeing to the transfer of your information to the United States
            and to Processing of your data globally. By providing your Personal
            Information, you consent to any transfer and Processing in
            accordance with this Policy.
          </p>

          <h2>10. CHILDREN’S PRIVACY</h2>
          <p>
            The Services are not directed to children under 13 (and in certain
            jurisdictions under the age of 16) years of age, and HelloPrivacy
            does not knowingly collect Personal Information from children under
            13 (and in certain jurisdictions under the age of 16) years of age.
            If you are under the age of 18, you must have your parent’s
            permission to access the Services. HelloPrivacy urges parents to
            instruct their children never to give out their real names,
            addresses, or phone numbers, without parental permission, when
            online. If you learn that your child has provided us with Personal
            Information without your consent, you may alert us at HelloPrivacy
            LLC, 53 West James Street, Suite 401, Lancaster, PA 17603, Attn:
            Privacy Policy. If we learn that we have collected any Personal
            Information from children under 13 (and in certain jurisdictions
            under the age of 16), we will promptly take steps to delete such
            information and terminate the child’s account.
          </p>

          <h2>11. REDRESS/COMPLIANCE AND ACCOUNTABILITY</h2>
          <p>
            If you have any questions about our privacy practices, this Privacy
            Policy, or how to lodge a complaint with the appropriate authority,
            please contact HelloPrivacy LLC, 53 West James Street, Suite 401,
            Lancaster, PA 17603, Attn: Privacy Policy]. We will address your
            concerns and attempt to resolve any privacy issues in a timely
            manner.
          </p>

          <h2>12. OTHER RIGHTS AND IMPORTANT INFORMATION</h2>
          <h2>12.1 CHANGES TO OUR PRIVACY POLICY AND PRACTICES</h2>
          <p>
            This Privacy Policy may change from time to time. You understand and
            agree that you will be deemed to have accepted the updated Privacy
            Policy if you use the Services after the updated Privacy Policy is
            posted on the Services. If at any point you do not agree to any
            portion of the Privacy Policy then in effect, you must immediately
            stop using the Services.
          </p>
          <ul className="list-disc">
            <li>
              <p>
                <strong>Revision to the Privacy Policy.</strong> We may revise
                this Privacy Policy in our sole discretion, so review it
                periodically. If you continue to visit this Site and use the
                services made available to you after such changes have been
                made, you hereby provide your consent to the changes.
              </p>
            </li>
            <li>
              <p>
                <strong>Posting of Revised Privacy Policy.</strong> If there are
                any material changes to this Privacy Policy, HelloPrivacy will
                notify you by email or as otherwise required by applicable law.
                We will post any adjustments to the Privacy Policy on this web
                page, and the revised version will be effective immediately when
                it is posted (or upon notice as applicable). If you are
                concerned about how your information is used, bookmark this page
                and read this Privacy Policy periodically.
              </p>
            </li>
          </ul>

          <h2>12.2 CALIFORNIA PRIVACY RIGHTS</h2>
          <p>
            California law permits users who are California residents to request
            and obtain from us once a year, free of charge, a list of the Third
            Parties to whom we have disclosed their Personal Information (if
            any) for their direct marketing purposes in the prior calendar year,
            as well as the type of Personal Information disclosed to those
            parties. HelloPrivacy does not share Personal Information with Third
            Parties for their own marketing purposes.
          </p>

          <h2>13. DEFINITIONS</h2>
          <p>
            The following capitalized terms shall have the meanings herein as
            set forth below.
          </p>
          <ul className="list-disc">
            <li>
              <p>
                <strong>“Agent”</strong> means any Third Party that Processes
                Personal Information pursuant to the instructions of, and solely
                for, HelloPrivacy or to which HelloPrivacy discloses Personal
                Information for use on its behalf.
              </p>
            </li>
            <li>
              <p>
                <strong>“Personal Information”</strong> is any information
                relating to an identified or identifiable natural person (
                <strong>“Individual”</strong>).
              </p>
            </li>
            <li>
              <p>
                <strong>“Process”</strong> or <strong>“Processing”</strong>{' '}
                means any operation which is performed upon Personal
                Information, whether or not by automatic means, such as
                collection, recording, organization, structuring, storage,
                adaptation or alteration, retrieval, consultation, use,
                disclosure by transmission, dissemination or otherwise making
                available, alignment or combination, restriction, erasure, or
                destruction.
              </p>
            </li>
            <li>
              <p>
                <strong>“Third Party”</strong> is any company, natural or legal
                person, public authority, agency, or body other than the
                Individual, HelloPrivacy or HelloPrivacy’s Agents.
              </p>
            </li>
          </ul>

          <h2>14. REVISION HISTORY</h2>
          <ul className="list-disc">
            <li>
              <p>Privacy Policy: Effective: April 3rd, 2019</p>
            </li>
            <li>
              <p>Privacy Policy: Effective: June 4th, 2018</p>
            </li>
            <li>
              <p>Privacy Policy: Effective: January 1st, 2018</p>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  )
}
