import React, { useEffect, useState } from 'react'

import { apiGet } from 'AppRoot/api-request'

export default function LoginProviders(props) {
  const { setCopy } = props
  const [gmailLink, setGmailLink] = useState('')
  // This will run once on mount (because of the empty array as the second arg, it won't be run again)
  useEffect(() => {
    ;(async function () {
      const link = await apiGet('/gmail-auth/get-token')
      setGmailLink(
        typeof link === 'string' || link instanceof String ? link : ''
      )
    })()
  }, [])

  return (
    <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-darkerGrey text-lg">
      <div className="w-full max-w-xs mx-auto mt-10 sm:max-w-sm md:max-w-md">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="flex flex-col justify-center items-center">
            <div className="mb-2">
              <div className="flex items-center flex-col mb-2">
                <h1 className="text-gray-900 text-3xl font-bold text-center mb-2">
                  Almost There!
                </h1>
                <h2 className="text-gray-900 text-xl font-bold text-center mb-6">
                  We need to authorize your email to complete removals
                </h2>
                <div className="mb-2">
                  <a
                    href={gmailLink.replaceAll('"', '')}
                    className="border-indigo-600 shadow appearance-none border rounded-full appearance-none border rounded-full google-button mb-2"
                  >
                    <div className="flex justify-center items-center">
                      <img
                        src="https://www.google.com/favicon.ico"
                        className="google-img"
                      />
                      <p className="google-text">Sign in with Google</p>
                    </div>
                  </a>
                  <a
                    href={gmailLink.replaceAll('"', '')}
                    className="border-indigo-600 shadow appearance-none border rounded-full appearance-none border rounded-full google-button mb-2"
                  >
                    <div className="flex justify-center items-center">
                      <img
                        src="https://www.yahoo.com/favicon.ico"
                        className="google-img"
                      />
                      <p className="google-text">Sign in with Yahoo</p>
                    </div>
                  </a>
                  <a
                    href={gmailLink.replaceAll('"', '')}
                    className="border-indigo-600 shadow appearance-none border rounded-full appearance-none border rounded-full google-button"
                  >
                    <div className="flex justify-center items-center">
                      <img
                        src="https://ow2.res.office365.com/owalanding/2022.7.15.01/images/favicon.ico?v=4"
                        className=" google-img"
                      />
                      <p className="google-text">Sign in with Outlook</p>
                    </div>
                  </a>
                </div>
              </div>
              <button
                type="button"
                className="underline text-gray-500 text-sm italic"
                onClick={() => setCopy(true)}
              >
                Why do we need your email?
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
