import React from 'react'

export default function Verified(props) {
  return (
    <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-darkerGrey text-lg">
      <div className="w-full max-w-xs mx-auto mt-10 sm:max-w-sm md:max-w-md">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-gray-900 text-3xl font-bold text-center mb-6">
              Authentication successful
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
