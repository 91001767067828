import React from 'react'

import { InfoPageHeader, Footer, ArrayBanner } from 'Components'

import guides from './data/guides.js'

const siteNames = Object.keys(guides)
const guideLinks = siteNames.map((siteName, index) => (
  <div key={`guide-link-${index}`}>
    <a target="_blank" className="text-midBlue" href={guides[siteName]}>
      {siteName}
    </a>
  </div>
))

const styles = {
  columnTitle: 'text-2xl font-bold mb-8'
}

export default function OptOutGuides() {
  return (
    <div className="text-darkerGrey text-lg">
      <ArrayBanner />
      <InfoPageHeader selected="optOutGuides" pageTitle="Opt Out Guides" />

      <div className="mx-24 lg:mx-auto max-w-page mb-24 flex flex-col lg:items-start lg:flex-row lg:justify-between gap-0-16 gap-y-16 lg:gap-x-16 lg:gap-y-0">
        <div className="border border-lighterGrey rounded-4xl p-16 flex-grow">
          <div className={styles.columnTitle}>
            Learn how to remove your info from:<sup>*</sup>
          </div>
          <div className="guide-links grid grid-flow-row md:grid-flow-col md:grid-rows-optOutGuides gap-y-2 md:gap-x-1 text-sm">
            {guideLinks}
          </div>
          <div className="text-sm mt-4 flex flex-col space-y-4">
            <p>
              *These sites often change or update their opt our processes, so we
              routinely check back to keep them up to date.
            </p>
          </div>
        </div>

        <div className="border border-lighterGrey rounded-4xl p-16 lg:max-w-lg inline-block">
          <div className={styles.columnTitle}>
            Why remove your data from these sites?
          </div>
          <div className="text-xl flex flex-col space-y-4">
            <p>
              According to the Federal Trade Commission (FTC), data brokers,
              people search sites and other online sources have information
              about nearly every US consumer, which increases your risk of
              identity theft, hacks, robo calls, and more.
            </p>
            <p>
              Our comprehensive guides show how you can start the process of
              removing yourself and your information from these dangerous sites.
            </p>
            <p>
              To keep yourself safe, we recommend searching these sites and
              removing yourself every 2-3 months.
            </p>
            <p>
              We offer free scans to see what sites you're exposed on and
              affordable services to do ongoing scans and removals.
            </p>
            <p>Get your free scan now:</p>
          </div>
          <div className="mt-8 flex flex-row justify-center">
            <a href="/" className="button-standard mb-16">
              See if Your Info is Exposed
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
