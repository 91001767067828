import React from 'react'

import * as SVGs from 'Components/Icons'

export default function CTABlock(props) {
  const { title, text, svg } = props

  const Svg = SVGs[svg]

  return (
    <div className="cta-block flex col-span-2 lg:col-span-1">
      <a
        href="/sign-up"
        className="cta-block-link relative flex flex-row justify-between p-0 mb-8 transition-shadow duration-200 ease-linear bg-white"
      >
        <div className="cta-block-content flex flex-row justify-between items-start mr-16 my-8 ml-8">
          <div className="w-20 mr-8">
            <Svg className="w-20 h-20 mr-8" />
          </div>
          <p>
            <span className="text-xl font-extrabold">{title}</span> {text}
          </p>
        </div>
        <div className="right-arrow absolute top-0 right-0 w-4 h-full bg-greyBlue transition-colors duration-200 ease-linear" />
      </a>
    </div>
  )
}
