import React from 'react'

import * as SVGs from 'Components/Icons'

export default function SvgWithText(props) {
  const { svg, type, title, subtitle, text, fillStyle, svgStyle } = props

  const Svg = SVGs[svg]

  if (type === SvgWithText.Vertical) {
    return (
      <div className="flex flex-col items-center basis-1/3">
        <Svg className={`${fillStyle} w-32 h-32`} />
        <p className="text-2xl font-bold mt-4">{title}</p>
        {subtitle && (
          <p className="text-lg text-center font-semibold text-greyBlue mb-2">
            {subtitle}
          </p>
        )}
        <p className="text-lg text-center">{text}</p>
      </div>
    )
  } else {
    return (
      <>
        <Svg
          className={`${fillStyle} ${svgStyle} col-span-2 lg:col-span-1 mx-auto lg:mx-0 mx-auto lg:mx-0 w-16 h-16`}
        />
        <p className="col-span-8 lg:col-span-4 text-center lg:text-left text-lg">
          <strong>{title}</strong> {text}
        </p>
      </>
    )
  }
}

SvgWithText.Vertical = 'Vertical'
SvgWithText.Horizontal = 'Horizontal'
