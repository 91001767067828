import React from 'react'
import trustedBy from 'Static/images/trusted-by.png'

export default function PressStrip(props) {
  const { version } = props

  const text =
    version === 'partners'
      ? 'Trusted by Fortune 500 companies and millions of users'
      : 'Trusted by millions of users and Fortune 500 companies'

  const extraClassNames = version === 'partners' ? 'press-strip-partners' : ''

  return (
    <div
      className={`flex flex-col space-y-4 lg:flex-row lg:space-y-0 justify-between items-center flex-half max-w-page border border-lightGrey rounded-xl bg-white text-lightGrey mx-8 px-12 py-8 text-xl max-h-98px font-bold space-x-4 ${extraClassNames}`}
    >
      <div className="lg:flex-even text-center lg:text-left">{text}</div>
      <img
        src={trustedBy}
        className="lg:flex-even h-pressStripImageHeight w-pressStripImageWidth"
      />
    </div>
  )
}
