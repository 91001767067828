import React from 'react'

export default function EmailBlock(props) {
  const { title, email } = props

  return (
    <div className="border border-lighterGrey rounded-4xl p-16 flex-grow">
      <div className="text-2xl font-bold mb-8 font-proxima-condensed">
        {title}
      </div>
      <div>
        {' '}
        <a className="underline text-midBlue" href={`mailto:${email}`}>
          {email}
        </a>
      </div>
    </div>
  )
}
