import React from 'react'
import favicons from 'by-site-automation-favicons'
import { SvgSpinner } from 'Components/Icons'

export default function ScanningProgress(props) {
  const { brokers, brokerCount, brokersScanned } = props

  const brokerIds = Object.keys(brokers)
  const currentBrokerId =
    brokerIds[
      brokersScanned === 0 ? 0 : Math.min(brokersScanned, brokerIds.length) - 1
    ]
  const currentBroker = brokers[currentBrokerId]
  const favicon = Object.keys(favicons).includes(currentBrokerId)
    ? favicons[currentBrokerId]
    : favicons[0]

  const percentDone =
    brokerCount > 0 ? Math.floor((brokersScanned / brokerCount) * 100) : 0

  return (
    <div className="scanning-block flex flex-col border-0 border-t-4 border-purple w-auto lg:w-full max-w-scanning rounded-sm px-6 py-8 mb-12 mx-8 lg:mx-auto">
      <h3 className="text-center text-xl font-extrabold text-darkerGrey tracking-wide flex flex-row justify-center items-center space-x-2">
        <SvgSpinner className="inline-block h-16px w-16px" />
        <span>Scanning:</span>
        {favicon && (
          <img className="inline-block h-16px w-16px" src={favicon} />
        )}
        <span>{currentBroker.name}</span>
      </h3>
      <div className="mb-6 text-center">
        {brokersScanned} of {brokerCount} data brokers scanned for your private
        info...
      </div>
      <div className="h-4 rounded-sm border border-lightGrey p-0 mb-2">
        <div
          className="h-full bg-purple progressBar"
          style={{ width: `${percentDone}%` }}
        />
      </div>
      <div className="flex flex-row justify-between text-xs text-lighterGrey font-bold uppercase">
        <div>0 sites</div>
        <div>{brokerCount} sites</div>
      </div>
    </div>
  )
}
