/**
 * @param {string} url
 * @param {Object.<string,any>} data
 * @return {Promise<Object.<string,any>>}
 */
export async function apiPost(url, data) {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (!resp.ok) {
      return await handleRequestFailure(resp)
    } else {
      return await resp.json()
    }
  } catch (err) {
    return { error: true, message: err.message }
  }
}

export async function apiGet(url) {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin'
    })

    if (!resp.ok) {
      return await handleRequestFailure(resp)
    } else {
      return await resp.json()
    }
  } catch (err) {
    return { error: true, message: err.message }
  }
}

export async function apiDelete(url) {
  try {
    const resp = await fetch(url, {
      method: 'DELETE',
      cache: 'no-cache',
      credentials: 'same-origin'
    })

    if (!resp.ok) {
      return await handleRequestFailure(resp)
    } else {
      return await resp.json()
    }
  } catch (err) {
    return { error: true, message: err.message }
  }
}

async function handleRequestFailure(resp) {
  try {
    const txt = await resp.text()
    return { error: true, message: extractErrorMessage(txt) }
  } catch (err) {
    return { error: true, message: 'unknown?' }
  }
}

function extractErrorMessage(content) {
  let msg
  try {
    const o = JSON.parse(content)
    if (o.message) {
      msg = o.message
    } else {
      throw new Error('<no message>')
    }
  } catch (err) {
    msg = content
  }

  if (!msg) {
    msg = '(Unknown API Error)'
  }

  return msg
}
