import React from 'react'
import { debug as createDebug } from 'debug'

import Error from './Error'

const debug = createDebug('privacy-api:hello-privacy')

export default function SearchForm(props) {
  const { doSearch, setLastError, lastError } = props

  function doSubmit(e) {
    e.preventDefault()

    const vals = {}
    try {
      for (const k of ['fname', 'lname', 'age']) {
        vals[k] = e.target[k].value
      }
    } catch (err) {
      console.log(err)
    }

    vals.age = parseInt(vals.age, 10) || null

    debug('Validating basic info', vals)

    const currentYear = new Date().getFullYear()
    const maxAge = currentYear - 1900
    const minAge = 1

    const validations = {
      fname: {
        validate: (val) => val.length >= 1 && val.length <= 100,
        message: 'First name must be between 1 and 100 characters'
      },
      lname: {
        validate: (val) => val.length >= 1 && val.length <= 100,
        message: 'Last name must be between 1 and 100 characters'
      },
      age: {
        validate: (val) =>
          typeof val === 'number' && val >= minAge && val <= maxAge,
        message: `Age must be between ${minAge} and ${maxAge}`
      }
    }

    for (const field in validations) {
      if (!validations[field].validate(vals[field])) {
        debug(`Failed validation for ${field}: '${vals[field]}'`)

        setLastError(validations[field])
        return
      }
    }

    if (
      Object.values(vals).filter(Boolean).length === Object.keys(vals).length
    ) {
      setLastError(null)
      doSearch(vals)
    } else {
      console.warn('Incomplete search request')
    }
  }

  return (
    <div className="mb-4">
      <form onSubmit={doSubmit}>
        <Error lastError={lastError} />
        <div className="mx-auto flex flex-col md:flex-row justify-around">
          <div className="mb-4 text-center md:mb-0 md:text-left">
            <input
              type="text"
              placeholder="First Name"
              name="fname"
              className="f-search font-proxima-reg mb-4 md:mb-0"
            />
            <input
              type="text"
              placeholder="Last Name"
              name="lname"
              className="f-search font-proxima-reg mb-4 md:mb-0"
            />
            <input
              type="text"
              placeholder="Age"
              name="age"
              className="f-search font-proxima-reg w-16 mb-4 md:mb-0"
            />
          </div>

          <div className="flex flex-col items-center">
            <button className="button-standard">Scan My Name Now Free</button>
            <div className="text-md mt-2 drop-shadow">
              Results in 30 seconds. No card required.
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
