import React from 'react'

export default function Error(props) {
  const { lastError } = props

  if (lastError) {
    return (
      <div className="mb-8 font-xl bg-white text-exposed text-center font-bold rounded px-8 py-4">
        {lastError.message}
      </div>
    )
  }

  return null
}
