import React, { useState } from 'react'

import { SvgClose, SvgMenu } from 'Components/Icons'
import logo from 'Static/images/hello-privacy-logo.png'

const styles = {
  menuHorizontalPadding: 'px-8',
  mobileMenuTextColor: 'darkerGrey',
  desktopMenuTextColor: 'white',
  desktopMenuSpacing: 'space-x-4 xl:space-x-12',
  selected: (color) => `inline-block border-${color} border-b-2`,
  scanButton:
    'font-bold bg-transparent text-white border-2 border-white rounded-full py-2 px-8 rounded-3xl whitespace-no-wrap transition-colors duration-150 ease-out hover:bg-invertedButton'
}

export default function Header(props) {
  const { showLinks = true, selected } = props

  const [expanded, setExpanded] = useState(false)

  const menuLinks = [
    [
      {
        name: 'personal',
        href: '/',
        text: 'Personal'
      },
      {
        name: 'partners',
        href: '/partners',
        text: 'Partner Solutions'
      },
      {
        name: 'optOutGuides',
        href: '/opt-out-guides',
        text: 'Opt Out Guides'
      }
    ],
    [
      {
        name: 'login',
        href: '/login',
        text: 'Log In'
      },
      {
        name: 'signup',
        href: '/sign-up',
        text: 'Free Privacy Scan'
      }
    ]
  ]

  const toggleMenu = () => setExpanded(!expanded)

  return (
    <>
      <div
        className={`hidden md:flex mx-2 xl:mx-auto max-w-page flex-row justify-between items-center ${styles.desktopMenuSpacing} text-lg`}
      >
        <a className="inline-block lg:mr-32" href="/">
          <img className="h-32px w-188px" src={logo} />
        </a>
        {showLinks && (
          <>
            <div
              className={`flex flex-row justify-center ${styles.desktopMenuSpacing}`}
            >
              {menuLinks[0].map((link, index) => (
                <a
                  key={`menu-link-desktop-0-${index}`}
                  className={`menu-link ${
                    selected === link.name ? 'selected' : ''
                  }`}
                  href={link.href}
                >
                  {link.text}
                </a>
              ))}
            </div>
            <div
              className={`flex flex-row justify-center items-center ${styles.desktopMenuSpacing}`}
            >
              {menuLinks[1].map((link, index) => (
                <a
                  key={`menu-link-desktop-1-${index}`}
                  className={
                    link.name === 'signup' ? styles.scanButton : 'menu-link'
                  }
                  href={link.href}
                >
                  {link.text}
                </a>
              ))}
            </div>
          </>
        )}
      </div>
      <div
        className={`flex md:hidden ${styles.menuHorizontalPadding} w-full flex-row justify-between items-center text-xl`}
      >
        <a className="inline-block" href="/">
          <img className="h-32px" src={logo} />
        </a>
        <a onClick={toggleMenu}>
          <SvgMenu className="w-8 h-8 fill-current text-white" />
        </a>
        {expanded && (
          <div
            className={`fixed top-0 left-0 right-0 bottom-0 z-30 bg-white w-full ${styles.menuHorizontalPadding} text-darkerGrey flex flex-col justify-start space-y-8 pt-64px pb-8`}
          >
            <a onClick={toggleMenu} className="flex flex-row justify-end">
              <SvgClose className="w-8 h-8 fill-current text-darkerGrey" />
            </a>
            {menuLinks.map((section, sectionIndex) =>
              section.map((link, index) => (
                <a
                  key={`menu-link-${sectionIndex}-${index}`}
                  className={
                    selected === link.name
                      ? styles.selected(styles.mobileMenuTextColor)
                      : ''
                  }
                  onClick={toggleMenu}
                  href={link.href}
                >
                  {link.text}
                </a>
              ))
            )}
          </div>
        )}
      </div>
    </>
  )
}
