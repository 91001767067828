import React from 'react'

import { InfoPageHeader, Footer, ArrayBanner } from 'Components'

import { EmailBlock } from './constituents'

const emailBlocks = {
  'General Questions:': 'privacysupport@helloprivacy.com',
  'Partnership Inquiries:': 'partners@helloprivacy.com',
  'Press:': 'press@helloprivacy.com'
}

export default function Contact() {
  return (
    <div className="text-darkerGrey text-lg">
      <ArrayBanner />
      <InfoPageHeader pageTitle="Get in touch. We’re here to help!" />

      <div className="mx-24 lg:mx-auto max-w-page mb-24 flex flex-col lg:flex-row lg:justify-between gap-0-16 gap-y-16 lg:gap-x-16 lg:gap-y-0">
        {Object.keys(emailBlocks).map((title, index) => (
          <EmailBlock
            key={`email-block-${index}`}
            title={title}
            email={emailBlocks[title]}
          />
        ))}
      </div>

      <div class="mx-24 flex flex-col lg:flex-row lg:max-w-page lg:mx-auto space-y-8 space-x-0 lg:space-y-0 lg:space-x-8 mb-16">
        <div>
          <iframe
            className="h-contactMapHeight w-contactMapWidth"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBHmGZa1xsLyvJb3HxsLyYq7Tvgu6mBudk&q=336+W+37th+St+%231200,+New+York,+NY+10018&center=40.7546262,-73.9962617&zoom=15"
          />
        </div>
        <div class="flex flex-col space-y-8">
          <div>
            <div>
              <strong>
                New York City Office <sub>(mail address)</sub>
              </strong>
            </div>
            <div>336 W. 37th St, STE 1200</div>
            <div>New York, NY, 10018</div>
          </div>
          <div>
            <div>
              <strong>Lancaster Office</strong>
            </div>
            <div>53 W James St, 4th Fl.</div>
            <div>Lancaster PA, 17603</div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
