import React from 'react'

import CarouselContent from './CarouselContent'
import TabbedCarousel from './TabbedCarousel'

import { SvgDarkWeb, SvgMonitoring, SvgRadar } from 'Components/Icons'

import checkGreen from 'Static/images/check-green.png'

export default function PartnerCarousel() {
  return (
    <TabbedCarousel
      id="partner-carousel"
      tabs={[
        {
          title: (
            <>
              <SvgMonitoring className="tab-icon" />
              <span>B2C - Improve your consumer product</span>
            </>
          ),
          content: (
            <CarouselContent
              id="b2c"
              blocks={[
                {
                  icon: checkGreen,
                  title: 'Add privacy features to your consumer product',
                  content:
                    'Integrate our privacy solutions into your product to improve your value proposition and increase revenue. Great for industries including identity protection, banking, fintech, credit, anti-virus, VPNs, password management, etc.'
                },
                {
                  icon: checkGreen,
                  title: 'Benefits of partnering',
                  content: (
                    <ul className="list-disc list-inside">
                      <li>
                        Generate more revenue by offering new privacy features
                      </li>
                      <li>
                        Increase conversions by providing more value to
                        consumers
                      </li>
                      <li>
                        Strengthen competitive advantage and expand your
                        offerings
                      </li>
                      <li>
                        Retain users longer with sticky, high-retention privacy
                        features
                      </li>
                    </ul>
                  )
                }
              ]}
            />
          )
        },
        {
          title: (
            <>
              <SvgRadar className="tab-icon" />
              <span>B2B - Improve your enterprise offering</span>
            </>
          ),
          content: (
            <CarouselContent
              id="b2b"
              blocks={[
                {
                  icon: checkGreen,
                  title: 'Add privacy features to your enterprise offering',
                  content:
                    "Leverage the protective nature of our privacy suite to broaden and differentiate your company's existing B2B services"
                },
                {
                  icon: checkGreen,
                  title: 'Benefits of partnering',
                  content: (
                    <ul className="list-disc list-inside">
                      <li>Increase revenue by adding privacy features</li>
                      <li>
                        Increase touchpoints & engagement with your clients
                      </li>
                      <li>
                        Differentiate your protection, identity, or security
                        services
                      </li>
                      <li>
                        Immediately showcase value to clients by offering a
                        Privacy Scan
                      </li>
                    </ul>
                  )
                }
              ]}
            />
          )
        },
        {
          title: (
            <>
              <SvgDarkWeb className="tab-icon" />
              <span>Internal - Protect your own employees</span>
            </>
          ),
          content: (
            <CarouselContent
              id="internal"
              blocks={[
                {
                  icon: checkGreen,
                  title: "Protect your company's employees",
                  content:
                    "Use our privacy solutions to enhance internal cybersecurity, protect senior employees' personal information, or expand employee benefit programs by offering privacy protection"
                },
                {
                  icon: checkGreen,
                  title: 'Benefits of partnering',
                  content: (
                    <ul className="list-disc list-inside">
                      <li>
                        Improve internal cybersecurity by removing exposed info
                        online
                      </li>
                      <li>
                        Protect key executives who are at a higher risk of
                        security threats
                      </li>
                      <li>
                        Protect all of your employees from the most common
                        privacy risks
                      </li>
                      <li>
                        Improve your employee benefits program to help retain
                        talent
                      </li>
                    </ul>
                  )
                }
              ]}
            />
          )
        }
      ]}
    />
  )
}
