import React, { useState } from 'react'
import { debug as createDebug } from 'debug'

import { COUNTRIES, US_STATES } from 'AppRoot/locations'

const debug = createDebug('privacy-api:hello-privacy')

const countryOptions = COUNTRIES.map((st) => (
  <option key={`sopt-${st.value.toLowerCase()}`} value={st.value.toUpperCase()}>
    {st.label}
  </option>
))

const stateOptions = US_STATES.map((st) => (
  <option key={`sopt-${st.value.toLowerCase()}`} value={st.value.toUpperCase()}>
    {st.label}
  </option>
))

export default function SelectLocation(props) {
  const { userProfile, doSearch, updateLocation, setLastError } = props

  const [isUs, setIsUs] = useState(userProfile.countryCode === 'US')

  function updateCountry(e) {
    const value = e.target.value
    const isUs = value === 'US'
    const nextProfile = {
      ...userProfile,
      country: value
    }

    if (isUs) {
      // If we're switching back to US, make sure we select the first state in the list,
      // because otherwise it's possible to end up with an empty state, even though it
      // appears to be selected.
      nextProfile.state = 'AL'
    }

    updateLocation(nextProfile)
    setIsUs(isUs)
  }

  function updateState(e) {
    updateLocation({
      ...userProfile,
      state: e.target.value
    })
  }

  function updateCity(e) {
    updateLocation({
      ...userProfile,
      city: e.target.value
    })
  }

  function submitForm() {
    debug('Validating basic info', userProfile)

    if (userProfile.city.length < 2 || userProfile.city.length > 100) {
      setLastError({ message: 'City must be between 2 and 100 characters' })
      return
    }

    if (userProfile.countryCode === 'US') {
      if (userProfile.state.length < 2 || userProfile.state.length > 100) {
        // State is a dropdown, so this shouldn't really be possible to trigger, but just in case
        setLastError({ message: 'You must select a state' })
        return
      }
    }

    debug('Submitting state form')

    doSearch()
  }

  return (
    <div className="mb-4">
      <form onSubmit={doSearch}>
        <div className="mx-auto flex flex-col md:flex-row md:space-x-4 items-center md:items-left space-y-8 md:space-y-0 justify-between mb-12 w-full max-w-scanning mx-auto text-xl">
          <div className="w-11/12 md:flex-thirds">
            <div className="font-bold mb-2">Country:</div>
            <select
              name="country"
              onChange={updateCountry}
              value={userProfile.countryCode}
              className="select-css focus:shadow-focusedInput select-cssfont-proxima-reg w-full"
            >
              {countryOptions}
            </select>
          </div>

          <div className="w-11/12 md:flex-thirds">
            <div className="font-bold mb-2">City:</div>
            <input
              type="text"
              name="city"
              onChange={updateCity}
              value={userProfile.city}
              className="location-field focus:shadow-focusedInput font-proxima-reg w-full"
            />
          </div>

          {isUs && (
            <div className="w-11/12 md:flex-thirds">
              <div className="font-bold mb-2">State:</div>
              <select
                name="state"
                onChange={updateState}
                value={userProfile.state}
                className="select-css focus:shadow-focusedInput font-proxima-reg w-full"
              >
                {stateOptions}
              </select>
            </div>
          )}

          {!isUs && (
            <div className="w-full md:flex-thirds">
              <div className="font-bold mb-2">Region/State:</div>
              <input
                name="state"
                onChange={updateState}
                value={userProfile.state}
                className="location-field focus:shadow-defaultButton font-proxima-reg w-full"
              />
            </div>
          )}
        </div>
        <div className="text-center">
          <a
            className="button-standard-thin w-11/12 md:w-auto"
            onClick={submitForm}
          >
            Continue
          </a>
        </div>
      </form>
    </div>
  )
}
