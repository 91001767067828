import React, { useState } from 'react'
import classNames from 'classnames'

import RecordDetail from './RecordDetail'
import favicons from 'by-site-automation-favicons'
import { SvgChevronDown, SvgWarning } from 'Components/Icons'

const sortRecords = (a, b) => {
  return b.score - a.score
}

export default function RecordList(props) {
  const { records, brokers, scanning, brokerCount, purchaseUrl } = props

  const [expandedRecords, setExpandedRecords] = useState({})
  const [currentPage, setCurrentPage] = useState(1)

  const recordsPerPage = 40

  function mapRecord(rec) {
    const { id, brokerId } = rec
    const toggleExpansion = () => {
      setExpandedRecords({
        ...expandedRecords,
        [id]: !expandedRecords[id]
      })
    }

    const brokerName = brokers[brokerId]
      ? brokers[brokerId].name
      : `Broker #${brokerId}`
    const addressEllipsis =
      expandedRecords[id] !== true && rec.addresses.length > 3 ? '\u2026' : ''
    const displayAddresses =
      expandedRecords[id] !== true && rec.addresses.length > 3
        ? [
            rec.addresses[0],
            rec.addresses[1],
            rec.addresses[2] + addressEllipsis
          ]
        : rec.addresses

    const relativeEllipsis =
      expandedRecords[id] !== true && rec.relatives.length > 3 ? '\u2026' : ''
    let displayRelatives = ['--']
    if (rec.relatives.length > 0) {
      displayRelatives =
        expandedRecords[id] !== true && rec.relatives.length > 3
          ? [
              rec.relatives[0],
              rec.relatives[1],
              rec.relatives[2] + relativeEllipsis
            ]
          : rec.relatives
    }

    const phoneNumbersEllipsis =
      expandedRecords[id] !== true && rec.phoneNumbers.length > 3
        ? '\u2026'
        : ''
    let displayPhoneNumbers = ['--']
    if (rec.phoneNumbers.length > 0) {
      displayPhoneNumbers =
        expandedRecords[id] !== true && rec.phoneNumbers.length > 3
          ? [
              rec.phoneNumbers[0],
              rec.phoneNumbers[1],
              rec.phoneNumbers[2] + phoneNumbersEllipsis
            ]
          : rec.phoneNumbers
    }

    const isExpanded = expandedRecords[id] === true

    return (
      <div className="table-row" key={`row-${rec.id}`}>
        <div
          onClick={toggleExpansion}
          className="c-record-list whitespace-no-wrap col-span-7 md:col-span-1 px-3 py-4 border border-b-1 md:border-b-0 border-t-0 border-l-0 border-r-0 font-bold pt-12 md:pl-8 md:pt-4"
          key={`${id}-broker`}
        >
          <div className="record-title-small inline-block md:hidden">Site:</div>
          <div className="inline-flex flex-row items-center">
            <img
              className="inline-block h-16px w-16px mr-2"
              src={
                favicons[brokerId] && typeof favicons[brokerId] === 'string'
                  ? favicons[brokerId]
                  : favicons[0]
              }
            />
            {brokerName}
          </div>
        </div>
        <div
          onClick={toggleExpansion}
          className="c-record-list col-span-7 md:col-span-1 px-3 py-4 border border-b-1 md:border-b-0 border-t-0 border-l-0 border-r-0"
          key={`${id}-name`}
        >
          <div className="record-title-small inline-block md:hidden">Name:</div>
          {rec.fullName}
          <span className="md:hidden">,&nbsp;</span>
          {rec.age && <div className="block mt-2">Age: {rec.age}</div>}
        </div>
        <div
          onClick={toggleExpansion}
          className="c-record-list col-span-7 md:col-span-1 px-3 py-4 border border-b-1 md:border-b-0 border-t-0 border-l-0 border-r-0"
          key={`${id}-addr`}
        >
          <div className="record-title-small inline-block md:hidden">
            Address:
          </div>
          <div className="inline-block blur-sm">
            {displayAddresses.map((val, index) => (
              <div key={`${id}-addr-${index}`} className="mb-2">
                {val}
              </div>
            ))}
          </div>
        </div>
        <div
          onClick={toggleExpansion}
          className="c-record-list col-span-7 md:col-span-1 px-3 py-4 border border-b-1 md:border-b-0 border-t-0 border-l-0 border-r-0"
          key={`${id}-relatives`}
        >
          <div className="record-title-small inline-block md:hidden">
            Relatives:
          </div>
          <div className="inline-block">
            {displayRelatives.map((val, index) => (
              <div key={`${id}-relatives-${index}`} className="mb-2">
                {val}
              </div>
            ))}
          </div>
        </div>
        <div
          onClick={toggleExpansion}
          className="c-record-list whitespace-no-wrap col-span-7 md:col-span-1 px-3 py-4 border border-b-1 md:border-b-0 border-t-0 border-l-0 border-r-0"
          key={`${id}-phoneNumbers`}
        >
          <div className="record-title-small inline-block md:hidden">
            Phone:
          </div>
          <div className="inline-block blur-sm">
            {displayPhoneNumbers.map((val, index) => (
              <div key={`${id}-phoneNumbers-${index}`} className="mb-2">
                {val}
              </div>
            ))}
          </div>
        </div>
        <div
          onClick={toggleExpansion}
          className="hidden md:block c-record-list col-span-7 md:col-span-1 px-3 py-4 border border-b-1 md:border-b-0 border-t-0 border-l-0 border-r-0 text-purple"
          key={`${id}-expandDesktop`}
        >
          {isExpanded ? 'Hide' : 'Show'}
          <SvgChevronDown
            className="inline-block rotating-icon ml-2 h-16px w-16px"
            style={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(360deg)'
            }}
          />
        </div>
        <div
          onClick={toggleExpansion}
          className="c-record-list col-span-7 md:col-span-1 px-3 py-4 pr-8 pb-12 md:pb-0"
          key={`${id}-more`}
        >
          <div className="record-title-small inline-block md:hidden">
            Status:
          </div>
          <div className="bg-exposed rounded px-4 py-1 text-white inline-flex flex-row justify-left items-center uppercase w-96px">
            <SvgWarning className="fill-current text-white h-16px w-16px mr-2" />
            <span className="font-bold tracking-wider text-xs font-proxima-condensed">
              Exposed
            </span>
          </div>
        </div>
        <div
          onClick={toggleExpansion}
          className="block md:hidden text-center c-record-list col-span-7 md:col-span-1 px-3 py-4 border border-b-1 md:border-b-0 border-t-0 border-l-0 border-r-0 text-purple"
          key={`${id}-expandMobile`}
        >
          {isExpanded ? 'Hide' : 'Show'}
          <SvgChevronDown
            className="inline-block rotating-icon ml-2 h-16px w-16px"
            style={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(360deg)'
            }}
          />
        </div>
        <div
          className={classNames('col-span-7', {
            hidden: !isExpanded
          })}
          key={`${id}-expanded`}
        >
          <RecordDetail
            record={rec}
            broker={brokers[brokerId] || {}}
            brokerCount={brokerCount}
            purchaseUrl={purchaseUrl}
          />
        </div>
      </div>
    )
  }

  function renderFakeRows() {
    const rows = []
    for (let index = 0; index < 3; index++) {
      rows.push(
        <div className="table-row" key={`placeholder-row-${index}`}>
          <div key={`placeholder-${index}-0`} className="textPlaceholder" />
          <div key={`placeholder-${index}-1`} className="textPlaceholder" />
          <div key={`placeholder-${index}-2`} className="textPlaceholder" />
          <div key={`placeholder-${index}-3`} className="textPlaceholder" />
          <div key={`placeholder-${index}-4`} className="textPlaceholder" />
          <div key={`placeholder-${index}-5`} className="textPlaceholder" />
          <div key={`placeholder-${index}-6`} className="textPlaceholder" />
        </div>
      )
    }
    return rows
  }

  const pageStart = (currentPage - 1) * recordsPerPage
  const pageEnd = Math.min(pageStart + recordsPerPage, records.length)

  // Create an array containing the numbers 1 through the total number of pages
  const pages = Array.from(
    { length: Math.ceil(records.length / recordsPerPage) },
    (_, i) => i + 1
  )
  const recordDivs = records
    .sort(sortRecords)
    .slice(pageStart, pageEnd)
    .map(mapRecord)

  return (
    <>
      <div
        className="scanning-block results-grid mb-16 w-auto lg:w-full max-w-scanning rounded-sm px-6 py-8 mx-8 lg:mx-auto"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <div className="hidden md:contents">
          <div className="h-record-list px-3 py-4 md:pl-8">Site</div>
          <div className="h-record-list px-3 py-4">Name</div>
          <div className="h-record-list px-3 py-4">Location</div>
          <div className="h-record-list px-3 py-4">Relatives</div>
          <div className="h-record-list px-3 py-4">Phone</div>
          <div className="h-record-list px-3 py-4">More Info</div>
          <div className="h-record-list px-3 py-4">Status</div>
        </div>
        {recordDivs}
        {scanning && renderFakeRows()}
      </div>
      {records.length > recordsPerPage && (
        <div className="flex flex-col space-y-4 justify-center items-center text-xl mb-16 md:flex-row md:space-y-auto md:space-x-4 md:items-baseline">
          <span>View more results:</span>
          {pages.map((page) => {
            const linkClass =
              page === currentPage
                ? ' bg-purple text-white'
                : 'cursor-pointer bg-lightestGrey'
            return (
              <a
                key={`pagination-${page}`}
                className={`text-bold rounded-full inline-block w-8 h-8 text-center line-height-8 ${linkClass}`}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </a>
            )
          })}
        </div>
      )}
    </>
  )
}
