import React from 'react'

export default function InfoCopy(props) {
  const { setCopy } = props

  return (
    <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-darkerGrey text-lg">
      <div className="w-full max-w-xs mx-auto mt-10 sm:max-w-sm md:max-w-md">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="flex flex-col justify-center items-center">
            <div className="mb-2">
              <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  className="text-xl ont-medium leading-normal"
                  id="modalLabel"
                >
                  Authorize us to remove on your behalf
                </h5>
                <button
                  type="button"
                  className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body relative p-4">
                <p>
                  Some brokers require email verification and additional steps
                  before they will remove your personal information from their
                  records. By authorizing your email we can interact and
                  complete these steps on your behalf.
                </p>
                <div className="mt-4 mb-6">
                  <ul className="list-disc list-inside">
                    <li className="mb-2">
                      We have been vetted and audited for security purposes by
                      major email providers
                    </li>
                    <li className="mb-2">
                      We will only use your email to submit, verify and complete
                      opt out processes for these brokers. We will not access,
                      store or use email for any other purpose
                    </li>
                    <li className="mb-2">
                      You can see every interaction we have and delete your
                      authorization at any time
                    </li>
                  </ul>
                  <p className="italic mt-6 text-gray-600">
                    Don’t have one of these email addresses?{' '}
                    <a href="/" className="underline">
                      Click here to set up an account
                    </a>{' '}
                    and come back to authorize
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="underline text-gray-500 text-sm italic"
                onClick={() => setCopy(false)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
