import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'

function clearCache() {
  window.localStorage.clear()
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name)
      })
    })
  }
  window.localStorage.setItem('version', VERSION)
}

if (window.localStorage.getItem('version') !== VERSION) {
  clearCache()
}

const Fallback = ({ resetError }) => {
  useEffect(() => {
    clearCache()
  }, [])
  return (
    <div className="font-proxima-reg">
      <div
        class="bg-red-50 border border-red-200 rounded-md p-4 mx-3 md:w-6/12 md:mx-auto mt-8"
        role="alert"
      >
        <div class="flex">
          <div class="ml-4">
            <h3 class="text-m text-red-800 font-semibold">
              It looks like we are having some internal issues
            </h3>
            <div class="mt-2 text-sm text-red-700">
              You can try refreshing the page, the problem might be temporary.
              Our team has been notified of the problem and will do our best to
              make sure it doesn't happen again!
            </div>
            <div class="mt-4">
              <div class="flex space-x-3">
                <button
                  type="button"
                  class="inline-flex justify-center items-center gap-2 rounded-md border border-red-700 px-3 py-2 font-medium text-red-700 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 transition-all text-sm"
                  onClick={() => {
                    resetError()
                  }}
                >
                  Try again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const fallback = (props) => <Fallback {...props} />

export function ErrorBoundary(props) {
  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      {props.children}
    </Sentry.ErrorBoundary>
  )
}
