import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { apiPost } from 'AppRoot/api-request'
import { usePersistentState } from 'AppRoot/hooks'

export default function EmailAuthorization() {
  const setVerified = usePersistentState('verified', false)[1]
  const setError = usePersistentState('authError', false)[1]
  const history = useHistory()
  const customerId = usePersistentState('emailAuthId', null)[0]

  // This will run once on mount (because of the empty array as the second arg, it won't be run again)
  useEffect(() => {
    ;(async function getToken() {
      const params = new URLSearchParams(window.location.search)
      const token = params.get('code')

      if (token && customerId) {
        await setVerified(true)
        try {
          await apiPost(
            `/gmail-auth/send-token/customers/${customerId}/actions/addToken`,
            { token }
          )
        } catch (e) {
          await setError(true)
        }

        history.push('/email-auth')
      }
    })()
  }, [customerId])

  return null
}
