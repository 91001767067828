import React from 'react'

export default function EmailUs() {
  return (
    <div className="">
      <a href="/contact" className="button-standard mb-2">
        Email Us to Learn More
      </a>
      <p className="text-xl">
        or{' '}
        <a className="underline text-lightBlue" href="/contact">
          request API documentation
        </a>
      </p>
    </div>
  )
}
