import React from 'react'

import Header from 'Components/Header'

export default function InfoPageHeader(props) {
  const {
    showLinks = true,
    selected = '',
    pageTitle = false,
    subTitle = false
  } = props

  return (
    <div className="pt-64px pb-16 mb-16 bg-heroGradientInfo bg-cover text-white flex flex-col items-center font-proxima-reg">
      <Header showLinks={showLinks} selected={selected} />

      {(pageTitle || subTitle) && (
        <div className="pt-16 px-8">
          {pageTitle && (
            <h1 className="text-5xl font-bold text-center">{pageTitle}</h1>
          )}
          {subTitle && <p className="text-3xl text-center">{subTitle}</p>}
        </div>
      )}
    </div>
  )
}
