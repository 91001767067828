import React from 'react'

export default function FeatureBox(props) {
  const { icon, title, text } = props

  return (
    <div className="flex flex-row justify-start items-start border border-lighterGrey px-8 py-12 space-x-4">
      {icon}
      <div>
        <div className="text-2xl font-bold">{title}</div>
        <p className="text-xl">{text}</p>
      </div>
    </div>
  )
}
