import React from 'react'

import checkPurple from 'Static/images/check-purple.png'

export default function FeatureBasic(props) {
  const { title, text } = props

  return (
    <div className="pr-4">
      <div className="flex flex-row space-x-2 justify-start items-center text-2xl font-bold">
        <img src={checkPurple} className="w-4 h-4" />
        <div>{title}</div>
      </div>
      <p className="text-xl">{text}</p>
    </div>
  )
}
