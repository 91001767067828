import React from 'react'

const styles = {
  header: 'flex flex-row justify-start items-center mb-4',
  icon: 'w-4 h-4 mr-4',
  title: 'text-lg font-bold',
  content: 'ml-8 mb-8'
}

export default function CarouselContent(props) {
  const { id, blocks = [] } = props

  return (
    <div>
      {blocks.map((block, index) => (
        <div key={`CarouselContent-${id}-${index}`}>
          <div className={styles.header}>
            <div className={styles.icon}>
              <img src={block.icon} />
            </div>
            <div className={styles.title}>{block.title}</div>
          </div>
          <div className={styles.content}>{block.content}</div>
        </div>
      ))}
    </div>
  )
}
