import React from 'react'

export default function Footer(props) {
  return (
    <>
      <div className="bg-footer text-white">
        <div className="pt-24 pb-12 px-2 mx-2 xl:mx-auto max-w-page xl:px-0 w-full max-w-page">
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start md:justify-between space-y-8 md:space-y-0">
            <div>
              <p className="footer-column-list-header text-center md:text-left md:w-auto">
                Company
              </p>
              <ul className="">
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/about">About</a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a
                    href="https://brandyourself.com/careers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Careers
                  </a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/contact">Contact</a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/partners">Partners</a>
                </li>
              </ul>
            </div>
            <div>
              <p className="footer-column-list-header text-center md:text-left md:w-auto">
                Contact
              </p>
              <ul className="">
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/contact">Contact Us</a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a
                    href="mailto:privacysupport@helloprivacy.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Email Us
                  </a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  (646) 863-8226
                </li>
              </ul>
            </div>
            {/*
            <div>
              <p className='footer-column-list-header text-center md:text-left md:w-auto'>Connect</p>
              <ul className=''>
                <li className='footer-column-list-item text-center md:text-left md:w-auto'><a href='' target='_blank' rel='noopener noreferrer'>LinkedIn</a></li>
                <li className='footer-column-list-item text-center md:text-left md:w-auto'><a href='' target='_blank' rel='noopener noreferrer'>Facebook</a></li>
                <li className='footer-column-list-item text-center md:text-left md:w-auto'><a href='' target='_blank' rel='noopener noreferrer'>Twitter</a></li>
              </ul>
            </div>
            */}
            <div>
              <p className="footer-column-list-header text-center md:text-left md:w-auto">
                Learn
              </p>
              <ul className="">
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="https://brandyourself.com/blog" target="_blank">
                    Blog
                  </a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/opt-out-guides">Opt Out Guides</a>
                </li>
              </ul>
            </div>
            <div>
              <p className="footer-column-list-header text-center md:text-left md:w-auto">
                Docs
              </p>
              <ul className="">
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/terms-of-service">Terms of Service</a>
                </li>
                <li className="footer-column-list-item text-center md:text-left md:w-auto">
                  <a href="/cookie-policy">Cookie Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-bottomFooter py-8 px-2 text-white text-lg">
        <div className="mx-2 xl:mx-auto w-full max-w-page">
          <strong>(C) 2021 HelloPrivacy LLC</strong> (A{' '}
          <a className="text-by" href="https://www.brandyourself.com">
            BrandYourself
          </a>{' '}
          Company)
        </div>
      </div>
    </>
  )
}
