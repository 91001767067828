import React from 'react'

import CarouselContent from './CarouselContent'
import TabbedCarousel from './TabbedCarousel'

import { SvgDarkWeb, SvgMonitoring, SvgRadar } from 'Components/Icons'
import checkPurple from 'Static/images/check-purple.png'
import checkGreen from 'Static/images/check-green.png'
import alert from 'Static/images/alert.png'
import trashcan from 'Static/images/trashcan.png'

export default function FeatureCarousel() {
  return (
    <TabbedCarousel
      id="feature-carousel"
      tabs={[
        {
          title: (
            <>
              <SvgMonitoring className="tab-icon" />
              <span>Data Broker Monitoring & Removal</span>
            </>
          ),
          content: (
            <CarouselContent
              id="what-is-broker-removal"
              blocks={[
                {
                  icon: checkPurple,
                  title: 'What is Data Broker removal?',
                  content:
                    'Instantly find, remove and monitor exposed private information on dozens of dangerous Data Broker and People Search sites.'
                },
                {
                  icon: alert,
                  title: 'Why it matters',
                  content:
                    '95% of individuals have home address, date of birth, phone and other sensitive information exposed publicly online for anyone to find. This puts them at high risk of identity theft, robocalls, spam, doxing, harassment, and more.'
                },
                {
                  icon: checkGreen,
                  title: 'How it works',
                  content: (
                    <ul className="list-disc list-inside">
                      <li>
                        Users get an instant scan to see what info is exposed
                        online
                      </li>
                      <li>We automatically remove their exposed info</li>
                      <li>
                        We monitor the web and alert them if any info re-appears
                      </li>
                      <li>
                        Dynamic dashboards and reporting to show status of
                        removals
                      </li>
                    </ul>
                  )
                }
              ]}
            />
          )
        },
        {
          title: (
            <>
              <img src={trashcan} className="tab-icon" />
              <span>Delete Old Accounts</span>
            </>
          ),
          content: (
            <CarouselContent
              id="what-is-account-deleter"
              blocks={[
                {
                  icon: checkPurple,
                  title: 'What is our Account Deleter?',
                  content:
                    'Scan your inbox for old accounts storing your data, and delete ones you no longer use to decrease your risk of data breaches.'
                },
                {
                  icon: alert,
                  title: 'Why it matters',
                  content:
                    'Nearly every week, hackers break into computer systems to steal personal data. Every time you sign up for something online, your data exists on another server that can be breached. This increases your risk of identity theft, spam and more.'
                },
                {
                  icon: checkGreen,
                  title: 'How it works',
                  content: (
                    <ul className="list-disc list-inside">
                      <li>
                        Scan your email inbox to see every account you've made
                      </li>
                      <li>
                        Select the unused accounts you no longer want storing
                        your data
                      </li>
                      <li>
                        Send a bulk data deletion request to delete all unwanted
                        accounts
                      </li>
                      <li>
                        Drastically minimize your digital footprint with a
                        single click
                      </li>
                    </ul>
                  )
                }
              ]}
            />
          )
        },
        {
          title: (
            <>
              <SvgDarkWeb className="tab-icon" />
              <span>Dark Web Monitoring</span>
            </>
          ),
          content: (
            <CarouselContent
              id="what-is-dark-web"
              blocks={[
                {
                  icon: checkPurple,
                  title: 'What is Dark Web Monitoring?',
                  content:
                    'Scan the Dark Web to see if your password or account details have been posted by hackers, so you can protect yourself.'
                },
                {
                  icon: alert,
                  title: 'Why it matters',
                  content:
                    "A Data Breach is when an organization's sensitive data is stolen by hackers. Passwords and other sensitive info in these breaches are often posted on the Dark Web, a feeding ground for identity thieves and spammers. Info exposed here puts you at high risk."
                },
                {
                  icon: checkGreen,
                  title: 'How it works',
                  content: (
                    <ul className="list-disc list-inside">
                      <li>
                        Scan Data Breaches to see if your accounts were
                        compromised
                      </li>
                      <li>Follow simple steps to protect yourself</li>
                      <li>
                        Monitor the Dark Web for any new Data Breaches moving
                        forward
                      </li>
                    </ul>
                  )
                }
              ]}
            />
          )
        },
        {
          title: (
            <>
              <SvgRadar className="tab-icon" />
              <span>Social Media Cleanup</span>
            </>
          ),
          content: (
            <CarouselContent
              id="what-is-dark-web"
              blocks={[
                {
                  icon: checkPurple,
                  title: 'What is Social Media Cleanup?',
                  content:
                    'Find and delete unprofessional social media posts that could hurt your career, damage job interviews, or cost you opportunities.'
                },
                {
                  icon: alert,
                  title: 'Why it matters',
                  content:
                    "Social media screenings now happen at every stage of your life. Whether you're applying for a job, internship, scholarship, or even renting or buying a home, you can assume your social media posts and photos will be vetted as part of the process."
                },
                {
                  icon: checkGreen,
                  title: 'How it works',
                  content: (
                    <ul className="list-disc list-inside">
                      <li>
                        Scan your Facebook and Twitter for unprofessional
                        posts/images
                      </li>
                      <li>
                        Filter your posts by profanity, drinking, drugs,
                        sexually explicit content, violence or bullying, and
                        more
                      </li>
                      <li>
                        Delete any posts that could cost you opportunities
                      </li>
                    </ul>
                  )
                }
              ]}
            />
          )
        }
      ]}
    />
  )
}
