const calculateBirthYear = (age) => new Date().getFullYear() - age

export default function createProfile(data) {
  return {
    birthYear: calculateBirthYear(data.age),
    birthMonth: new Date().getMonth() + 1,
    name: {
      first: data.firstName,
      last: data.lastName
    },
    otherNames: [],
    phoneNumbers: [],
    addresses: [
      {
        city: data.city,
        state: data.state,
        country: data.countryCode,
        current: true
      }
    ],
    emailAddresses: []
  }
}
