import React from 'react'

import { InfoPageHeader, Footer, ArrayBanner } from 'Components'

export default function CookiePolicy() {
  return (
    <div className="text-darkerGrey text-lg">
      <ArrayBanner />
      <InfoPageHeader
        pageTitle="Cookie Policy"
        subTitle="Last updated: June 4th, 2018"
      />
      <div
        id="cookie-policy"
        className="legal-text text-midGrey text-lg mx-24 lg:mx-auto max-w-text"
      >
        <p>
          At HelloPrivacy LLC, DBA BrandYourself (henceforth called
          "HelloPrivacy"), we believe in being clear and open about how we
          collect and use data related to you. Our Privacy Policy explains how
          we collect and use information from and about you when you use
          HelloPrivacy’s services. We provide this cookie policy to tell you
          more about why we use cookies and other similar identifying
          technologies, the types of cookies and similar technologies we use,
          and your choices in relation to these technologies.
        </p>

        <ol className="list-decimal">
          <li>
            <strong>Does HelloPrivacy use cookies?</strong>
            <p>
              Yes. As described in Section 3.3 of our Privacy Policy, we use
              cookies and other technologies to ensure everyone who uses
              HelloPrivacy has the best possible experience. Cookies also help
              us keep your account safe. By continuing to visit or use our
              services, you are agreeing to the use of cookies and similar
              technologies for the purposes we describe in this policy.
            </p>
          </li>
          <li>
            <strong>What is a cookie?</strong>
            <p>
              A cookie is a small file placed onto your device that enables
              HelloPrivacy features and functionality. For example, cookies
              enable us to identify your device, secure your access to
              HelloPrivacy and our sites generally, and even help us know if
              someone attempts to access your account from a different device.
              Cookies also enable you to easily share content on HelloPrivacy
              and help us serve relevant ads to you.
            </p>
          </li>
          <li>
            <strong>When does HelloPrivacy place cookies?</strong>
            <p>
              We use cookies on our site and mobile applications. Any browser
              visiting these sites will receive cookies from us.
            </p>
          </li>
          <li>
            <strong>What types of cookies does HelloPrivacy use?</strong>
            <p>
              We use two types: persistent cookies and session cookies. A
              persistent cookie helps us recognize you as an existing user, so
              it's easier to return to HelloPrivacy or interact with our
              services without signing in again. After you sign in, a persistent
              cookie stays in your browser and will be read by HelloPrivacy when
              you return to our site. Session cookies only last for as long as
              the session (usually the current visit to a website or a browser
              session).
            </p>
          </li>
          <li>
            <strong>Which HelloPrivacy entity is using cookies?</strong>
            <p>
              HelloPrivacy will be responsible for your personal data provided
              to, or collected by or for, our Services.
            </p>
          </li>
          <li>
            <strong>What are cookies used for?</strong>
            <p>
              HelloPrivacy uses cookies for a number of reasons, like protecting
              your HelloPrivacy data and account, helping us see which features
              are most popular, counting visitors to a page, improving our
              users’ experience, keeping our services secure, and just generally
              providing you with a better, more intuitive, and satisfying
              experience. The cookies we use generally fall into one of the
              following categories.
            </p>

            <div className="grid grid-cols-2">
              <div>Categories of Use</div>
              <div>Description</div>

              <div>Authentication</div>
              <div>
                If you're signed in to HelloPrivacy, cookies help us show you
                the right information and personalize your experience.
              </div>
              <div>Security</div>
              <div>
                We use cookies to enable and support our security features, and
                to help us detect malicious activity and violations of our User
                Agreement.
              </div>
              <div>Preferences, features and services</div>
              <div>
                Cookies can tell us which language you prefer and what your
                communications preferences are. They can help you fill out forms
                on HelloPrivacy more easily.
              </div>
              <div>Advertising</div>
              <div>
                We may use cookies to show you relevant advertising both on and
                off the HelloPrivacy site. We may also use a cookie to learn
                whether someone who saw an ad later visited and took an action
                (e.g. downloaded a white paper or made a purchase) on the
                advertiser's site. Similarly, our partners may use a cookie to
                determine whether we've shown an ad and how it performed, or
                provide us with information about how you interact with ads. We
                may also work with a partner to show you an ad on or off
                HelloPrivacy, such as after you've visited a partner's site or
                application.
              </div>
              <div>Performance, Analytics and Research</div>
              <div>
                Cookies help us learn how well our site and plugins perform in
                different locations. We also use cookies to understand, improve,
                and research products, features, and services, including when
                you access HelloPrivacy from other websites, applications, or
                devices such as your work computer or your mobile device.
              </div>
            </div>
          </li>
          <li>
            <strong>How are cookies used for advertising purposes?</strong>
            <p>
              Cookies and other ad technology such as beacons, pixels, and tags
              help us serve relevant ads to you more effectively. They also help
              us provide aggregated auditing, research, and reporting for
              advertisers, understand and improve our service, and know when
              content has been shown to you. Note: Because your web browser may
              request advertisements and beacons directly from third party ad
              network servers, these networks can view, edit, or set third party
              cookies, just as if you had requested a web page from their site.
              Ads served by HelloPrivacy may also set third party cookies.
            </p>
            <p>
              If you are logged in on HelloPrivacy.com or another Service that
              references this Cookie Policy and one of our cookies on your
              device identifies you, your usage (such as your browsing behavior)
              and log data (such as your IP address), will be associated by us
              with your account. We also use aggregate data from third parties
              and data from your profile and HelloPrivacy activity.
            </p>
            <p>
              If you are a HelloPrivacy member but logged out of your account on
              a browser, HelloPrivacy may still continue to log your interaction
              with our services on that browser for up to 30 days in order to
              generate usage analytics for our services, which analytics we may
              share in aggregate form with our advertising customers.
            </p>
            <p>
              Unless you clear these cookies from your browser, we may use this
              information to:
            </p>
            <ul className="list-circle">
              <li>provide more relevant, interest-based advertising</li>
              <li>
                provide aggregate reports of ads activity to advertisers and
                websites hosting the ads
              </li>
              <li>
                help website and app owners understand how visitors engage with
                their sites or apps
              </li>
              <li>
                detect and defend against fraud and other risks to protect users
                and partners
              </li>
              <li>improve our products</li>
            </ul>
            <p>
              We do not provide any personal information that we collect to
              advertisers unless you have given us permission to do so. You can
              opt-out of interest-based advertising from our customers, as well
              as our customers' advertising on HelloPrivacy based on data
              collected through third party sites, by adjusting your settings.
              Please note that opting out will not remove advertising from the
              pages you visit; it will mean that the ads you see may not be
              matched to your interests.
            </p>
          </li>
          <li>
            <strong>Controlling cookies</strong>
            <p>
              Most browsers allow you to control cookies through their settings
              preferences. However, if you limit the ability of websites to set
              cookies, you may worsen your overall user experience, since it
              will no longer be personalized to you. It may also stop you from
              saving customized settings like login information.
            </p>
          </li>
          <li>
            <strong>
              What to do if you don't want cookies to be set or want them to be
              removed?
            </strong>
            <p>
              If you do not want to receive cookies, you can also change your
              browser settings on your computer or other device you're using to
              access our services.
            </p>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  )
}
