import React from 'react'

import { Header, Footer, PressStrip, ArrayBanner } from 'Components'

import ribbonCutting from 'Static/images/ribbon-cutting.png'

const styles = {
  header: 'text-4xl font-extrabold mb-8 text-midGrey font-proxima-condensed',
  text: 'text-2xl mb-8'
}

export default function About() {
  return (
    <div className="font-proxima-reg text-darkerGrey">
      <ArrayBanner />
      {/* Privacy as a Service block */}
      <div className="text-white pt-64px pb-64 sm:pb-32 lg:pb-24 bg-heroGradientPartners bg-cover flex flex-col items-center relative">
        <Header />

        <h1 className="pageTitle font-proxima-bold my-16">
          Our Mission is to Keep You Safe Online
        </h1>

        <div className="flex flex-col md:flex-row mx-4 lg:mx-auto justify-between items-start space-x-8 max-w-page">
          <img
            className="laptop mb-2 flex-half w:9/12 sm:flex-half md:w-5/12"
            src={ribbonCutting}
          />
          <div className="flex-half">
            <p className="text-2xl mb-12">
              We're committed to protecting you from privacy threats including
              identity theft, spam, robo calls, stalkers, and other privacy
              risks.
            </p>
            <div className="flex flex-col lg:flex-row justify-start lg:justify-center items-center space-x-0 lg:space-x-4 space-y-4 lg:space-y-0">
              <a
                href="/contact"
                className="button-standard-thin w-9/12 lg:w-5/12"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>

        <PressStrip version="partners" />
      </div>

      <div className="flex flex-col lg:flex-row mt-24 mb-16 mx-8 xl:mx-auto lg:max-w-page space-x-0 space-y-16 lg:space-x-16 lg:space-y-0">
        <div className="flex-even">
          <h3 className={styles.header}>Who We Are:</h3>
          <p className={styles.text}>
            We are the same team behind BrandYourself.com, the largest provider
            of individual online reputation management tools in the world.
            HelloPrivacy is our new privacy product.
          </p>
          <p className={styles.text}>
            We started BrandYourself.com over 10 years ago to protect
            individuals from issues ranging from revenge porn to unprofessional
            social media posts. We were the first “ORM” company to approach and
            help average individuals.
          </p>
          <p className={styles.text}>
            Since then we've built a team of world class employees and helped
            over 1M customers (you might have seen us on Shark Tank or Dragons’
            Den, or in the press in general).
          </p>
          <p className={styles.text}>
            Most recently, we realized that nobody was helping individuals
            protect their privacy online. Just like enterprises have robust
            cybersecurity teams, individuals need to make sure their own
            personal information isn’t exposed or doesn’t get into the wrong
            hands.
          </p>
          <p className={styles.text}>
            So we spent the last 2 years building a new product designed to make
            individuals more protected online.
          </p>

          <h3 className={styles.header}>How Does It Work?</h3>
          <p className={styles.text}>
            We remove your personal information from places it shouldn’t be.
          </p>
          <p className={styles.text}>
            We remove your information from data brokers: we scan the web and
            identify what personal information data brokers have, and remove
            your information so nobody can find/use it. This saves you hundreds
            of hours of work you’d have to do yourself.
          </p>
          <p className={styles.text}>
            Dark Web Scans: we scan the dark web to see if a large company ever
            leaked your information via a breach, so you can immediately change
            any necessary passwords or user names, or simply delete the account.
          </p>
          <p className={styles.text}>
            Delete Old Accounts: we help identify at-risk accounts you no longer
            need (like an old Myspace account) and automatically delete it so
            you don’t have to worry about what information they have.
          </p>
        </div>

        <div className="flex-even">
          <h3 className={styles.header}>The Problem We Solve:</h3>
          <p className={styles.text}>
            Your personal and sensitive information is exposed all over the web.
          </p>
          <p className={styles.text}>
            Internet companies track everything we do&mdash;the sites we visit,
            the things we search, the items we buy, the messages we send, and so
            on. These companies don’t always keep this information private. They
            sell it to bad actors for a profit, or it leaks due to an accidental
            data breach.
          </p>
          <p className={styles.text}>
            The worst of these companies are known as “data brokers” or “people
            search sites”. They create profiles on you that anybody can access.
            They include information like your phone number, your email, your
            family members, your home address, court cases/criminal
            records/bankruptcies/divorces, who you are friends with and even
            your political or religious affiliations.
          </p>
          <p className={styles.text}>
            How would you feel if someone wanted to use this information against
            you?
          </p>
          <p className={styles.text}>
            These sites can be embarrassing at best, and dangerous at worst.
            They are treasure troves for identity thieves, hackers, spammers,
            robocallers, stalkers and blackmailers. In fact, the FTC did an
            investigation and unilaterally warned against these sites and
            recommended processes to help people opt out.
          </p>
          <p className={styles.text}>
            The issue is that there are dozens of these sites. Finding and
            deleting your information is incredibly time consuming. And worse,
            you can go through all that effort only for it to be repopulated
            again a few weeks later.
          </p>
          <p className={styles.text}>That’s where we come in.</p>

          <h3 className={styles.header}>How Do I Get Started?</h3>
          <p className={styles.text}>
            Get a free privacy scan now to remove personal info. See:
          </p>
          <ul className={`list-disc list-inside ${styles.text}`}>
            <li>
              Which data brokers are selling your personal information, and what
              data they have
            </li>
            <li>What information has been leaked onto the dark web</li>
            <li>Which old accounts you should delete</li>
            <li>What to do about it</li>
          </ul>
        </div>
      </div>

      <div className="mb-16 mx-auto flex flex-row justify-center">
        <a href="/" className="button-standard-thin">
          Get a Free Privacy Scan
        </a>
      </div>

      <Footer />
    </div>
  )
}
