import React from 'react'
import { SvgArrow } from 'Components/Icons'

export default function InfoPageHeader(props) {
  return (
    <div className="group bg-black p-3">
      <a href="https://array.com/products/privacy-protect">
        <p className="text-button text-center mr-1 group-hover:mr-0">
          HelloPrivacy is now Privacy Protect—an Array product. Learn more
          <SvgArrow className="inline ml-2 group-hover:ml-3" />
        </p>
      </a>
    </div>
  )
}
