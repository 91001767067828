module.exports = {
  '411.com': 'https://brandyourself.com/blog/privacy/411-com-opt-out/',
  'Acxiom.com':
    'https://brandyourself.com/blog/privacy/acxiom-opt-out-and-removal/',
  'Addresses.com':
    'https://brandyourself.com/blog/privacy/how-to-opt-out-remove-your-info-from-addresses-com/',
  'Addresssearch.com':
    'https://brandyourself.com/blog/privacy/addresssearch-opt-out/',
  'Advancedbackgroundchecks.com':
    'https://brandyourself.com/blog/privacy/advancedbackgroundchecks-opt-out/',
  'Allpeople.com': 'https://brandyourself.com/blog/privacy/allpeople-opt-out/',
  'Ancestry.com': 'https://brandyourself.com/blog/privacy/ancestry-opt-out/',
  'Anywho.com':
    'https://brandyourself.com/blog/privacy/anywho-com-what-it-is-how-to-opt-out/',
  'Archives.com':
    'https://brandyourself.com/blog/privacy/archives-com-opt-out/',
  'Arrestfacts.com':
    'https://brandyourself.com/blog/privacy/arrestfacts-opt-out/',
  'Backgroundalert.com':
    'https://brandyourself.com/blog/privacy/backgroundalert-opt-out/',
  'Beenverified.com':
    'https://brandyourself.com/blog/privacy/beenverified-opt-out/',
  'Blockshopper.com':
    'https://brandyourself.com/blog/privacy/blockshopper-opt-out/',
  'Checkpeople.com':
    'https://brandyourself.com/blog/privacy/checkpeople-opt-out/',
  'Checkthem.com': 'https://brandyourself.com/blog/privacy/checkthem-opt-out/',
  'Clustrmaps.com':
    'https://brandyourself.com/blog/privacy/clustrmaps-opt-out/',
  'Corporationwiki.com':
    'https://brandyourself.com/blog/privacy/corporationwiki-opt-outs/',
  'Courtrecords.org':
    'https://brandyourself.com/blog/privacy/courtrecords-opt-out/',
  'Cubib.com': 'https://brandyourself.com/blog/privacy/cubib-opt-out/',
  'Cyberbackgroundcheck.com':
    'https://brandyourself.com/blog/privacy/cyber-background-check-opt-out/',
  'DOBsearch.com': 'https://brandyourself.com/blog/privacy/dobsearch-opt-out/',
  'Epsilon.com': 'https://brandyourself.com/blog/privacy/epsilon-opt-out/',
  'FamilyTreeNow.com':
    'https://brandyourself.com/blog/privacy/familytreenow-opt-out/',
  'FastPeopleSearch.com':
    'https://brandyourself.com/blog/privacy/fastpeoplesearch-opt-out-and-removal/',
  'Golookup.com': 'https://brandyourself.com/blog/privacy/golookup-optout/',
  'Hauziz.com': 'https://brandyourself.com/blog/privacy/hauziz-opt-out/',
  'Homemetry.com': 'https://brandyourself.com/blog/privacy/homemetry-opt-out/',
  'IDTrue.com': 'https://brandyourself.com/blog/privacy/idtrue-opt-out/',
  'Infotracer.com':
    'https://brandyourself.com/blog/privacy/infotracer-opt-out/',
  'InstantCheckmate.com':
    'https://brandyourself.com/blog/privacy/opt-out-from-instant-checkmate/',
  'Instantpeoplefinder.com':
    'https://brandyourself.com/blog/privacy/instantpeoplefinder-opt-out/',
  'Intelius.com':
    'https://brandyourself.com/blog/fixing-negative-results/intelius-opt-out-process-explained/',
  'Jailbase.com': 'https://brandyourself.com/blog/privacy/jailbase-optout/',
  'Lexisnexis.com':
    'https://brandyourself.com/blog/privacy/lexisnexis-opt-out/',
  'Locatefamily.com':
    'https://brandyourself.com/blog/privacy/locatefamily-opt-out/',
  'Lookupanyone.com':
    'https://brandyourself.com/blog/privacy/lookupanyone-opt-out/',
  'Manta.com': 'https://brandyourself.com/blog/privacy/manta-opt-out/',
  'Mylife.com':
    'https://brandyourself.com/blog/privacy/opt-out-remove-information-from-mylife/',
  'Neighbor.Report':
    'https://brandyourself.com/blog/privacy/neighbor-report-opt-out/',
  'Newenglandfacts.com':
    'https://brandyourself.com/blog/privacy/newenglandfacts-opt-out/',
  'NJparcels.com': 'https://brandyourself.com/blog/privacy/njparcels-opt-out/',
  'Nuwber.com':
    'https://brandyourself.com/blog/orm/nuwber-opt-out-and-removal/',
  'OkCaller.com':
    'https://brandyourself.com/blog/guide/what-is-okcaller-and-how-do-you-opt-out/',
  'Peekyou.com': 'https://brandyourself.com/blog/privacy/peekyou-opt-out/',
  'Peoplebyname.com':
    'https://brandyourself.com/blog/privacy/peoplebyname-opt-out/',
  'Peoplefinder.com':
    'https://brandyourself.com/blog/privacy/peoplefinder-opt-out/',
  'PeopleFinders.com':
    'https://brandyourself.com/blog/fixing-negative-results/a-peoplefinders-opt-out-strategy-that-works/',
  'Peoplelooker.com':
    'https://brandyourself.com/blog/privacy/peoplelooker-opt-out/',
  'Peoplelookup.com':
    'https://brandyourself.com/blog/privacy/peoplelookup-opt-out/',
  'PeopleSearchNow.com':
    'https://brandyourself.com/blog/privacy/people-search-now-opt-out-process-2020-guide/',
  'PeopleSmart.com': 'https://brandyourself.com/blog/orm/peoplesmart-opt-out/',
  'Peoplewhiz.com':
    'https://brandyourself.com/blog/privacy/peoplewhiz-com-opt-out/',
  'Persopo.com': 'https://brandyourself.com/blog/privacy/persopo-opt-out/',
  'Phonebooks.com':
    'https://brandyourself.com/blog/privacy/phonebooks-opt-out/',
  'Phoneowner.com':
    'https://brandyourself.com/blog/privacy/phoneowner-opt-out/',
  'Pipl.com': 'https://brandyourself.com/blog/privacy/pipl-opt-out/',
  'Privateeye.com':
    'https://brandyourself.com/blog/privacy/privateeye-opt-out/',
  'Publicdatacheck.com':
    'https://brandyourself.com/blog/privacy/publicdatacheck-opt-out/',
  'Publicinfoservices.com':
    'https://brandyourself.com/blog/privacy/publicinfoservices-opt-out/',
  'Publicrecords.com':
    'https://brandyourself.com/blog/privacy/publicrecords-com-opt-out/',
  'Publicrecords360.com':
    'https://brandyourself.com/blog/privacy/publicrecords360-opt-out/',
  'Publicrecordsnow.com':
    'https://brandyourself.com/blog/privacy/publicrecordsnow-opt-out/',
  'Radaris.com':
    'https://brandyourself.com/blog/fixing-negative-results/remove-yourself-and-opt-out-from-radaris/',
  'Rehold.com': 'https://brandyourself.com/blog/privacy/rehold-opt-out/',
  'Revealname.com':
    'https://brandyourself.com/blog/privacy/revealname-opt-out/',
  'Searchpeoplefree.com':
    'https://brandyourself.com/blog/privacy/searchpeoplefree-opt-out/',
  'Searchquarry.com':
    'https://brandyourself.com/blog/privacy/searchquarry-opt-out/',
  'Smartbackgroundchecks.com':
    'https://brandyourself.com/blog/privacy/smartbackgroundchecks-opt-out/',
  'SocialCatfish.com':
    'https://brandyourself.com/blog/privacy/social-catfish-opt-out/',
  'Spokeo.com':
    'https://brandyourself.com/blog/privacy/spokeo-opt-out-the-most-effective-method/',
  'Spydialer.com': 'https://brandyourself.com/blog/privacy/spydialer-opt-out/',
  'Spyfly.com': 'https://brandyourself.com/blog/privacy/spyfly-opt-out/',
  'Thatsthem.com':
    'https://brandyourself.com/blog/privacy/thatsthem-com-opt-out/',
  'Truecaller.com': 'https://brandyourself.com/blog/privacy/truecaller-unlist/',
  'TruePeopleSearch.com':
    'https://brandyourself.com/blog/privacy/true-people-search-removal/',
  'Truthfinder.com':
    'https://brandyourself.com/blog/orm/truthfinder-opt-out-and-remove-information/',
  'USA-people-search.com':
    'https://brandyourself.com/blog/privacy/usa-people-search-opt-out/',
  'USphonebook.com':
    'https://brandyourself.com/blog/brand-yourselfcom/usphonebook-com-removal-opt-out-options/',
  'USSearch.com':
    'https://brandyourself.com/blog/privacy/ussearch-com-opt-out/',
  'Whitepages.com':
    'https://brandyourself.com/blog/privacy/opt-out-and-remove-listings-from-whitepages/',
  'YellowPages.com':
    'https://brandyourself.com/blog/privacy/yellow-pages-opt-out-personal-info-removal-2020-guide/',
  'Zabasearch.com':
    'https://brandyourself.com/blog/privacy/zabasearch-opt-out-and-removal-process-a-step-by-step-guide/',
  'ZoomInfo.com': 'https://brandyourself.com/blog/privacy/zoominfo-opt-out/'
}
