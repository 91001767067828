import React from 'react'

import {
  Header,
  Footer,
  PressStrip,
  Testimonials,
  ArrayBanner
} from 'Components'

import laptop2 from 'Static/images/laptop-2.png'
import api from 'Static/images/api.png'
import whiteLabel from 'Static/images/white-label.png'

import {
  EmailUs,
  FeatureBasic,
  FeatureBox,
  FeatureCarousel,
  PartnerCarousel
} from './constituents'

export default function PartnerSolutions() {
  return (
    <div className="font-proxima-reg ">
      <ArrayBanner />
      {/* Privacy as a Service block */}
      <div className="text-white pt-64px pb-64 sm:pb-32 xl:pb-24 bg-heroGradientPartners bg-cover flex flex-col items-center relative">
        <Header selected="partners" />

        <h1 className="pageTitle font-proxima-bold my-16">
          Privacy as a Service
        </h1>

        <div className="flex flex-col md:flex-row mx-4 lg:mx-auto justify-between items-start space-x-8 max-w-page">
          <img
            className="laptop mb-2 flex-half w:9/12 sm:flex-half md:w-5/12"
            src={laptop2}
          />
          <div className="flex-half">
            <h3 className="text-2xl font-bold mb-12">
              Add privacy features to help your customers remove their personal
              info from the web:
            </h3>
            <ul className="list-disc list-inside text-xl mb-12">
              <li className="mb-2">
                Enhance your product with powerful privacy features
              </li>
              <li className="mb-2">
                Increase user engagement, conversion, retention and revenue
              </li>
              <li className="mb-2">
                Scalable industry-leading Privacy APIs & white label solutions
              </li>
            </ul>
            <div className="flex flex-col xl:flex-row justify-start items-center space-x-0 xl:space-x-4 space-y-4 xl:space-y-0">
              <a
                href="/contact"
                className="button-standard-thin w-9/12 xl:w-5/12"
              >
                Email Us
              </a>
              <a
                href="/contact"
                className="button-standard-thin-inverted w-9/12 xl:w-5/12"
              >
                Request API Docs
              </a>
            </div>
          </div>
        </div>

        <PressStrip version="partners" />
      </div>

      {/* Provide powerful privacy features block */}
      <div className="relative flex flex-col items-center text-midGrey pt-24 mb-16 max-w-page mx-auto">
        <div className="section-header-full-width">
          Provide powerful privacy features to your customers or employees:
        </div>

        <FeatureCarousel />

        <div className="section-header-full-width mt-16">
          Our partners span a wide range of use cases:
        </div>

        <PartnerCarousel />

        <div className="section-header-full-width mt-16">
          Integration is simple, fast and flexible:
        </div>

        <div className="flex flex-auto flex-col lg:flex-row justify-between md:space-y-16 lg:space-x-16 mb-16 mx-4 xl:mx-auto">
          <div className="flex flex-col space-y-8 flex-half mb-8 lg:mb-0">
            <FeatureBox
              icon={<img src={api} className="w-16 h-16 mt-2" />}
              title="1. API"
              text="Integrate Privacy Protection features into your existing platform as a value add, new revenue driver or vehicle for retention."
            />
            <FeatureBox
              icon={<img src={whiteLabel} className="w-16 h-16 mt-2" />}
              title="2. White Label"
              text="Send your customers to a separate web portal that looks and feels like your brand, but is powered by us."
            />
          </div>
          <div className="flex-half grid grid-cols-2">
            <FeatureBasic
              title="Scalable"
              text="Our platform is designed to scale with you, whether you have ten or ten million users."
            />
            <FeatureBasic
              title="Easy Setup"
              text="Integrating our API is a breeze, including a quick start sandbox and thorough documentation."
            />
            <FeatureBasic
              title="Security Compliant"
              text="We exceed industry standards in security, including third party security consulting audits."
            />
            <FeatureBasic
              title="Trusted & Proven"
              text="Our API is used by Fortune 500 companies, leading privacy companies, and millions of users."
            />
            <FeatureBasic
              title="Flexible Pricing"
              text="No matter your situation, from one user to 100 million, our pricing will fit your needs."
            />
            <FeatureBasic
              title="Industry Leading"
              text="Our API success rate is 99.999%, and we continually leverage cutting edge tech."
            />
          </div>
        </div>

        <div className="text-center">
          <EmailUs />
        </div>
      </div>

      {/* Testimonials block */}
      <div>
        <div className="section-header-full-width mb-16">
          Fortune 500 companies and millions of users trust us with their
          privacy:
        </div>

        <div className="max-w-page mx-auto mb-16">
          <Testimonials page="partners" />
        </div>

        <div className="text-center mb-16">
          <EmailUs />
        </div>
      </div>
      <Footer />
    </div>
  )
}
