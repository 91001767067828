import React, { useEffect } from 'react'

import { Footer, InfoPageHeader, ArrayBanner } from 'Components'

import SelectLocation from './SelectLocation'
import Error from './Error'

const styles = {
  container:
    'text-darkGrey flex flex-col justify-center pb-16 mx-8 main-content-container',
  subTitle: 'sub-title mb-2',
  title: 'text-5xl font-bold text-center mb-8'
}

export default function ConfirmLocation(props) {
  const {
    lastError,
    setLastError,
    selectingLocation,
    setSelectingLocation,
    doSearch,
    updateLocation,
    userProfile
  } = props

  const suggestedLocationName = `${userProfile.city}, ${
    userProfile.countryCode === 'US'
      ? userProfile.state
      : userProfile.countryName
  }`

  useEffect(() => {
    // If the user didn't get a city in their GeoIP suggestion, just take them to the select state
    if (userProfile.city.trim() === '') {
      setSelectingLocation(true)
    }
  }, [])

  function selectOtherLocation() {
    setSelectingLocation(true)
  }

  if (selectingLocation) {
    return (
      <div>
        <ArrayBanner />
        <InfoPageHeader />

        <div className={styles.container}>
          <h1 className={styles.title}>
            Your Free Privacy Scan is Seconds Away!
          </h1>
          <h2 className={styles.subTitle}>Enter your city and state:</h2>

          <Error lastError={lastError} />

          <SelectLocation
            doSearch={doSearch}
            userProfile={userProfile}
            updateLocation={updateLocation}
            setLastError={setLastError}
          />
        </div>

        <Footer />
      </div>
    )
  } else {
    return (
      <div>
        <ArrayBanner />
        <InfoPageHeader />

        <div className={styles.container}>
          <h1 className={styles.title}>
            Your Free Privacy Scan is Seconds Away!
          </h1>
          <h2 className={styles.subTitle}>
            Do you live in {suggestedLocationName}?
          </h2>
          <p className="text-center text-xl mb-8">
            This will help narrow down your results from billions of records.
          </p>

          <Error lastError={lastError} />

          <div className="flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
            <a
              className="button-yesno-thin-secondary"
              onClick={selectOtherLocation}
            >
              No
            </a>
            <a className="button-yesno-thin" onClick={doSearch}>
              Yes
            </a>
          </div>
        </div>

        <Footer />
      </div>
    )
  }
}
