import React from 'react'

import { SvgWarning } from 'Components/Icons'

const standardInfo = ['Name', 'Address', 'Age', 'Relatives']

const onlyExtendedInfo = (infoType) => !standardInfo.includes(infoType)

export default function RecordDetail(props) {
  const { record, broker, brokerCount, purchaseUrl } = props

  const extendedInfoTypes = broker.infoTypes?.filter(onlyExtendedInfo) || []

  return (
    <div className="expanded-grid">
      <div>
        {extendedInfoTypes.length > 0 && (
          <>
            <div className="font-bold mb-4">This site also exposes your:</div>
            {extendedInfoTypes.map((type, index) => (
              <div key={`${record.id}-infotypes-${index}`}>
                <SvgWarning className="fill-current inline-block text-exposed h-16px w-16px mr-2" />
                {type}
              </div>
            ))}
          </>
        )}
      </div>
      <div>
        <div className="font-bold mb-4">This info puts you at risk of:</div>
        <ul>
          <li>Identity theft</li>
          <li>Robocalls</li>
          <li>Email spam</li>
          <li>Hackers</li>
          <li>Stalkers and creeps</li>
          <li>Companies buying your data</li>
        </ul>
      </div>
      <div>
        <div className="font-bold mb-4">To protect yourself:</div>
        <div>
          Our software can automatically remove all of your sensitive personal
          information from this site (and all {brokerCount} major data brokers).
          Click the button below to protect yourself.
        </div>
      </div>
      <div className="col-span-3 text-center my-12">
        <a className="button-standard" href={purchaseUrl}>
          Remove My Exposed Info
        </a>
      </div>
    </div>
  )
}
