import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

/*
 * BY/HP is not currently using Sentry. Please consult with
 * the team before uncommenting the code below.
 */

/*
Sentry.init({
  dsn: 'https://7bd8ea0081c1429cbcf724abc6ba681c@o1259293.ingest.sentry.io/4504922850328576',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
  enabled: PRODUCTION,
  version: VERSION
})
*/

import App from './App'
import { ErrorBoundary } from './components/ErrorBoundary'

import './css/styles.css'
import './css/email-authorization.css'

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('mountNode')
)
