import React, { useState, useEffect } from 'react'
import { LoginProviders, InfoCopy, Verified, ErrorScreen } from './constituents'
import { usePersistentState } from 'AppRoot/hooks'

export default function EmailAuthorization() {
  const [showCopy, setCopy] = useState(false)
  const verified = usePersistentState('verified', false)[0]
  const [customerId, setCustomerId] = usePersistentState('emailAuthId', null)
  const errorOccurred = usePersistentState('authError', false)[0]

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setCustomerId(params.get('customerId') ?? customerId)
  }, [])

  if (errorOccurred) {
    return <ErrorScreen />
  }
  if (verified) {
    return <Verified />
  }
  if (showCopy) {
    return <InfoCopy setCopy={setCopy} />
  }
  return <LoginProviders setCopy={setCopy} />
}
