import React, { useState } from 'react'

export default function TabbedCarousel(props) {
  const { id, tabs } = props

  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div className="tabbed-carousel">
      <ul className="tabs flex-half">
        {tabs.map((tab, index) => {
          const tabClass = index === selectedTab ? 'selected' : ''

          return (
            <li
              key={`${id}-title-${index}`}
              className={tabClass}
              onMouseOver={() => setSelectedTab(index)}
              onClick={() => setSelectedTab(index)}
            >
              <div className="selected-indicator" />
              {tab.title}
            </li>
          )
        })}
      </ul>
      <div className="content flex-half flex">
        {tabs.map((tab, index) => {
          const tabClass =
            index === selectedTab
              ? 'tab-content visible'
              : 'tab-content invisible'

          return (
            <div key={`${id}-tab-${index}`} className={tabClass}>
              {tab.content}
            </div>
          )
        })}
      </div>
    </div>
  )
}
