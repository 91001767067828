import React from 'react'

import { Footer, InfoPageHeader, ArrayBanner } from 'Components'

import ScanningProgress from './ScanningProgress'
import RecordList from './RecordList'
import Error from './Error'

const styles = {
  title: 'text-5xl font-bold text-center mb-8'
}

const continueButton = (
  purchaseUrl,
  clearScan,
  buttonText = 'Remove My Exposed Info'
) => (
  <div className="flex flex-col items-center space-y-4 mb-12 mx-8 md:mx-auto">
    <a className="button-standard" href={purchaseUrl}>
      {buttonText}
    </a>
    <a
      className="underline cursor-pointer text-lighterGrey font-bold"
      onClick={clearScan}
    >
      Start New Scan
    </a>
  </div>
)

export default function ScanResults(props) {
  const {
    brokerCount,
    brokers,
    brokersScanned,
    clearScan,
    lastError,
    purchaseUrl,
    records,
    scanning
  } = props

  if (scanning) {
    return (
      <div>
        <ArrayBanner />
        <InfoPageHeader />

        <div className="main-content-container">
          <h1 className={styles.title}>
            Scanning the web for your personal information:
          </h1>

          <ScanningProgress
            brokers={brokers}
            brokerCount={brokerCount}
            brokersScanned={brokersScanned}
          />

          {records.length > 0 && continueButton(purchaseUrl, clearScan)}

          <RecordList records={records} brokers={brokers} scanning={scanning} />
        </div>

        <Footer />
      </div>
    )
  } else if (records.length > 0) {
    return (
      <div>
        <ArrayBanner />
        <InfoPageHeader />

        <div className="main-content-container">
          <h1 className={styles.title}>
            <span>
              We Found <span className="text-exposed">{records.length}</span>{' '}
              Pieces of Exposed Info About You
            </span>
          </h1>

          <h3 className="text-center text-2xl mb-2 mx-8 md:mx-auto">
            <strong>You may be at high risk</strong> of identity theft, hacks,
            spam, robo-dials and other threats.
          </h3>
          <h3 className="text-center text-2xl mb-16 mx-8 md:mx-auto">
            Sign up below to automatically monitor & remove your info from these
            sites.
          </h3>

          <Error lastError={lastError} />

          {continueButton(purchaseUrl, clearScan)}

          <RecordList
            records={records}
            brokers={brokers}
            scanning={scanning}
            brokerCount={brokerCount}
            purchaseUrl={purchaseUrl}
          />
        </div>

        <Footer />
      </div>
    )
  } else {
    return (
      <div>
        <ArrayBanner />
        <InfoPageHeader />

        <div className="main-content-container">
          <h1 className={styles.title}>
            Good news, we didn’t find any exposed info!
          </h1>

          <h3 className="text-center text-2xl mb-2 mx-8 md:mx-auto">
            <strong>You may still be at high risk</strong> of identity theft,
            hacks, spam, robo-dials and other threats.
          </h3>
          <h3 className="text-center text-2xl mb-16 mx-8 md:mx-auto">
            Sign up below to automatically monitor & remove your info from these
            sites.
          </h3>

          {continueButton(purchaseUrl, clearScan, 'Continue')}
        </div>

        <Footer />
      </div>
    )
  }
}
