import React, { useEffect } from 'react'

import { nanoid } from 'nanoid'
import { debug as createDebug } from 'debug'

import { usePersistentState } from 'AppRoot/hooks'
import { apiGet, apiPost } from 'AppRoot/api-request'
import { createScan } from 'AppRoot/data-types'

import {
  ConfirmLocation,
  LandingPageContent,
  ScanResults
} from './constituents'

const pollDelay = 1000
const pollForDone = 3 // every ~3sec we check to see if we're done
let intvRecordPoll = null
let inPoll = false
let pollCount = 0
let analyticsConfigs = {}

const debug = createDebug('privacy-api:hello-privacy')

const initialState = {
  brokerCount: null,
  brokersScanned: 0,
  scanning: false,
  selectingLocation: false,
  hasSetName: false,
  hasSetLocation: false,
  records: [],
  brokers: {},
  lastError: null,
  scanId: null,
  initialQueryString: '',
  userProfile: {
    firstName: '',
    lastName: '',
    age: 0,
    city: '',
    state: '',
    countryCode: '',
    countryName: ''
  }
}

export default function LandingPage(props) {
  // ===============================================
  // State
  // ===============================================

  const [customerId] = usePersistentState('customerId', nanoid())
  const [brokerCount, setBrokerCount] = usePersistentState(
    'brokerCount',
    initialState.brokerCount
  )
  const [brokersScanned, setBrokersScanned] = usePersistentState(
    'brokersScanned',
    initialState.brokersScanned
  )
  const [scanning, setScanning] = usePersistentState(
    'scanning',
    initialState.scanning
  )
  const [selectingLocation, setSelectingLocation] = usePersistentState(
    'selectingLocation',
    initialState.selectingLocation
  )
  const [hasSetName, setHasSetName] = usePersistentState(
    'hasSetName',
    initialState.hasSetName
  )
  const [hasSetLocation, setHasSetLocation] = usePersistentState(
    'hasSetLocation',
    initialState.hasSetLocation
  )
  const [records, setRecords] = usePersistentState(
    'records',
    initialState.records
  )
  const [brokers, setBrokers] = usePersistentState(
    'brokers',
    initialState.brokers
  )
  const [lastError, setLastError] = usePersistentState(
    'lastError',
    initialState.lastError
  )
  const [scanId, setScanId] = usePersistentState('scanId', initialState.scanId)
  const [userProfile, setUserProfile] = usePersistentState(
    'userProfile',
    initialState.userProfile
  )

  const queryString = window.location.search.trim()
  const [initialQueryString, setInitialQueryString] = usePersistentState(
    'initialQueryString',
    queryString
  )

  if (initialQueryString.trim() === '' && queryString.length > 0) {
    setInitialQueryString(queryString)
  }

  const signupUrl = 'https://helloprivacy.com/sign-up' + initialQueryString

  // This will run once on mount (because of the empty array as the second arg, it won't be run again)
  useEffect(() => {
    if (window.helloPrivacy) {
      const configFromServer = JSON.parse(window.helloPrivacy)
      analyticsConfigs = configFromServer.analyticsConfigs || {}

      debug('Set analytics configs: %o', analyticsConfigs)
    }

    // Clear any errors from previous page loads
    setLastError(null)

    // If the user hasn't selected a country yet, fill in our best guess based on GeoIP
    if (userProfile.countryCode === '') {
      debug('Setting profile country based on GeoIP')
      setUserProfile({
        ...userProfile,
        ...getUserProfileLocation()
      })
    }

    // Kick off a request to get the broker list
    ;(async function () {
      try {
        const resp = await apiGet('/papi/brokers')
        if (!resp.error) {
          debug('Got brokers')
          const manifest = {}
          resp
            .filter((broker) => broker.enabled)
            .forEach((b) => {
              manifest[b.id] = b
            })
          setBrokers(manifest)

          // If the user previously started a scan, poll the server to pick up where it left off
          if (scanId) {
            debug('Scan id exists, updating status')
            const status = await updateScanStatus(scanId)
            if (status === 'done') {
              debug('Scan is done, fetching records')
              await getRecords(scanId)
            } else {
              debug(`Scan is ${status}, resuming polling`)
              setScanning(true)
              initPolling(scanId)
            }
          }
        } else {
          setLastError(resp)
        }
      } catch (err) {
        console.warn('error getting brokers', err.message)
        setLastError(err)
      }
    })()
  }, [])

  // ===============================================
  // Functions
  // ===============================================

  function getUserProfileLocation() {
    const mountNode = document.getElementById('mountNode')
    return {
      city: mountNode.getAttribute('data-userCity'),
      state: mountNode.getAttribute('data-userState'),
      countryCode: mountNode.getAttribute('data-userCountryCode'),
      countryName: mountNode.getAttribute('data-userCountryName')
    }
  }

  function trackEvent(eventName, conf = 'helloprivacy') {
    if (typeof window.gtag !== 'undefined') {
      if (
        conf &&
        typeof analyticsConfigs[conf] === 'object' &&
        analyticsConfigs[conf].enabled
      ) {
        const sendTo =
          analyticsConfigs[conf].routingCode ||
          analyticsConfigs[conf].measurementId

        debug(
          `Tracking GA4 event: window.gtag('event', '${eventName}', { send_to: '${sendTo}' })`
        )

        window.gtag('event', eventName, { send_to: sendTo })
      } else {
        debug(
          `Tried to route event to '${conf}', but couldn't find that config!`
        )
      }
    } else {
      debug(
        'Not tracking event (%s, %s), no configs are enabled!',
        eventName,
        conf
      )
    }
  }

  async function updateScanStatus(scanId) {
    debug(`Updating scan status for ${scanId}`)
    const resp = await apiGet(`/papi/scans/${scanId}`)
    debug('Scan status', resp)
    if (resp.error) {
      setLastError(resp)
    } else {
      setLastError(null)
    }

    if (resp.status === 'done') {
      clearInterval(intvRecordPoll)
      setScanning(false)
    }

    return resp.status || null
  }

  async function getScanProgress(scanId) {
    debug(`Updating scan progress for ${scanId}`)
    const resp = await apiGet(`/papi/scans/${scanId}/brokerScans`)
    debug('Broker scans', resp)

    if (resp.error) {
      setLastError(resp)
    } else {
      setBrokersScanned(
        resp.filter((brokerScan) =>
          ['failed', 'success', 'retrying'].includes(brokerScan.status)
        ).length
      )
    }
  }

  async function getRecords(scanId) {
    debug(`Getting records for ${scanId}`)
    const resp = await apiGet(`/papi/scans/${scanId}/records`)
    debug('Records response', resp)
    if (resp.error) {
      setLastError(resp)
    } else {
      setLastError(null)
    }

    pollCount++
    if (Array.isArray(resp)) {
      setRecords(resp)
    }
  }

  function initPolling(scanId) {
    debug('Starting poll')
    if (intvRecordPoll) {
      clearInterval(intvRecordPoll)
      inPoll = false
      pollCount = 0
    }

    if (scanId) {
      intvRecordPoll = setInterval(async () => {
        if (inPoll) {
          return
        }

        // keep us from having more than one poll
        // running at once...
        inPoll = true

        try {
          await getRecords(scanId)
          await getScanProgress(scanId)

          inPoll = false

          if (pollCount % pollForDone === 0) {
            await updateScanStatus(scanId)
          }
        } catch (err) {
          console.warn('fatal poll error', err.message)
          inPoll = false
        }
      }, pollDelay)
    }
  }

  async function doSearch() {
    debug('Doing search')
    const scanRecord = createScan(customerId, userProfile)
    debug('Scan record', scanRecord)

    try {
      setScanning(true)

      const resp = await apiPost('/papi/scans', scanRecord)
      if (!resp.error) {
        debug('Scan created')
        setLastError(null)
        setHasSetLocation(true)
        setScanId(resp.id)
        setRecords([])
        setBrokersScanned(0)
        setBrokerCount(resp.brokerCount)
        initPolling(resp.id)
      } else {
        debug(`Error creating scan: ${resp.error}`)
        setScanning(false)
        setHasSetLocation(false)
        setLastError(resp)
      }
    } catch (err) {
      console.warn('error running search', err.message)
      setScanning(false)
      setHasSetLocation(false)
      setLastError(err)
    }
  }

  function setBasicInfo(formData) {
    debug('Setting basic info', formData)
    trackEvent('search')

    setUserProfile({
      ...userProfile,
      firstName: formData.fname,
      lastName: formData.lname,
      age: Number(formData.age)
    })
    setHasSetName(true)
  }

  function updateLocation(formData) {
    debug('Setting location', formData)
    setUserProfile({
      ...userProfile,
      city: formData.city,
      state: formData.state,
      countryCode: formData.country
    })
  }

  function clearScan() {
    // This resets all state except the brokers, which are pulled at component mount time
    setBrokersScanned(initialState.brokersScanned)
    setScanning(initialState.scanning)
    setSelectingLocation(initialState.selectingLocation)
    setHasSetName(initialState.hasSetName)
    setHasSetLocation(initialState.hasSetLocation)
    setRecords(initialState.records)
    setLastError(initialState.lastError)
    setScanId(initialState.scanId)
    setUserProfile({
      ...initialState.userProfile,
      ...getUserProfileLocation()
    })
  }

  // ===============================================
  // Render
  // ===============================================

  if (!hasSetName) {
    return (
      <LandingPageContent
        setBasicInfo={setBasicInfo}
        setLastError={setLastError}
        lastError={lastError}
      />
    )
  }

  if (!hasSetLocation) {
    return (
      <ConfirmLocation
        doSearch={doSearch}
        updateLocation={updateLocation}
        userProfile={userProfile}
        selectingLocation={selectingLocation}
        setSelectingLocation={setSelectingLocation}
        lastError={lastError}
        setLastError={setLastError}
      />
    )
  }

  return (
    <ScanResults
      brokers={brokers}
      brokerCount={brokerCount}
      brokersScanned={brokersScanned}
      clearScan={clearScan}
      lastError={lastError}
      purchaseUrl={signupUrl}
      records={records}
      scanning={scanning}
      signupUrl={signupUrl}
      trackEvent={trackEvent}
    />
  )
}
